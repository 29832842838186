import { ApiService } from "../apiservices/ApiService";
import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import Endpoints from "./Endpoints";

export default class TaskService {

    public static getTasksGivenQuery = (query: TaskQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: TaskListResponse }}> => {
        var url = Endpoints.TASKS_ENDPOINT + `?status=${query.status || ""}&type=${query.type || ""}&id=${query.id || ""}&assignedTo=${query.assignedTo || ""}&createdBy=${query.createdBy || ""}&cashActivityId=${query.cashActivityId || ""}&country=${query.country || ""}`;
        if (query.createdStartTime) {
            url += `&createdStartTime=${query.createdStartTime}`;
        }
        if(query.createdEndTime) {
            url += `&createdEndTime=${query.createdEndTime}`
        }
        if (query.lastUpdatedStartTime) {
            url += `&lastUpdatedStartTime=${query.lastUpdatedStartTime}`;
        }
        if(query.lastUpdatedEndTime) {
            url += `&lastUpdatedEndTime=${query.lastUpdatedEndTime}`
        }
        if (query.minimumProgress) {
            url += `&minimumProgress=${query.minimumProgress}`;
        }
        if (query.maximumProgress) {
            url += `&maximumProgress=${query.maximumProgress}`;
        }
        return ApiService.request({
            url: url,
            method: "get",
            data: query
        }, cancelator);
    }

    public static updateTask = (update: UpdateTaskRequest, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: any }}> => {
        return ApiService.request({
            url: Endpoints.TASKS_ENDPOINT,
            method: "patch",
            data: update,
        }, cancelator);
    }
}