import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Badge } from "reactstrap";


interface SidebarItemProps extends RouteComponentProps {
    title: string;
    badgeColor?: string;
    badgeText?: string;
    icon?: Icon;
    to: string;
}

class SidebarItem extends React.Component<SidebarItemProps, {}> {
    private getSidebarItemClass(path: string) {
        return this.props.location.pathname === path ? "active" : "";
    }

    public render() {
        const Icon = this.props.icon;
        return (
            <li className={"sidebar-item " + this.getSidebarItemClass(this.props.to)}>
                <NavLink to={this.props.to} className="sidebar-link" activeClassName="active">
                    {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
                    {this.props.title}
                    {this.props.badgeColor && this.props.badgeText ? (
                        <Badge color={this.props.badgeColor} size={18} className="sidebar-badge">
                            {this.props.badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            </li>
        );
    }
}

const wrapper = withRouter(SidebarItem);
export { wrapper as SidebarItem }
