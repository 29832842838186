import 'notyf/notyf.min.css';

import { Notyf } from 'notyf';
import { RootStore } from "./RootStore";

export interface ToastModel {
    title?: string;
    message: string;
    toastType: ToastMessageType;
    duration?: ToastDuration;
}

export enum ToastMessageType {
    Success = 1,
    Info,
    Error,
    Warning
}

export enum ToastDuration {
    Normal = 1,
    Long,
    Max
}

export class ToastNotificationStore {
    private root: RootStore;
    private notify = new Notyf({
        types: [
          {
            type: 'info',
            backgroundColor: "#3086FF"
          }, 
          {
            type: 'warning',
            backgroundColor: "#d9534f"
          }
        ]
    });

    constructor(rootStore: RootStore) {
        this.root = rootStore;
    }

    public showNotif(message: ToastModel) {
        const duration = message.duration ? message.duration : ToastDuration.Normal;
        this.notify.options.duration = this.getDuration(duration);
        const toastType = message.toastType === ToastMessageType.Info
        ? "info"
        : message.toastType === ToastMessageType.Warning
        ? "warning"
        : message.toastType === ToastMessageType.Error
        ? "error"
        : "success";
        this.notify.open({
            type: toastType,
            message: message.message
          }
        );
    }

    private getDuration(duration: ToastDuration) {
        if (duration === ToastDuration.Long) {
            return 8000;
        } else if (duration === ToastDuration.Normal) {
            return 3000;
        } else {
            return 15000;
        }
    }
    
}