import * as React from "react";

import { inject, observer } from "mobx-react";

import { AuthLayout } from "../layouts/AuthLayout";
import { Loader } from "../nav/Loader";
import { LoginErrorView } from "../../pages/auth/LoginErrorView";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { SiteLayout } from "../layouts/SiteLayout";

interface AuthenticatedRouteProps {
    root?: RootStore;
}

@inject("root") @observer 
export class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps, {}> {
    public render() {
        const loginStatus = this.props.root.auth.LoginStatus;
        if (loginStatus === RenderingStatus.Waiting) {
            return (
                <AuthLayout>
                    <Loader>
                        <div>We are signing you in...</div>
                    </Loader>
                </AuthLayout>
            );
        }

        if (loginStatus === RenderingStatus.Error) {
            return (
                <AuthLayout>
                    <LoginErrorView/>
                </AuthLayout>
            );
        }
         
        return (
            <SiteLayout>
                {this.props.children}
            </SiteLayout>
        );
    }
}


