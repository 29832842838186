import * as React from "react";

import { Card, CardBody, Media, Spinner } from "reactstrap";
import { Lambda, computed, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { Activity } from "react-feather";
import { AppSettingStore } from "../../../stores/AppSettingStore";
import BeaconService from "../../../services/Beacon.service";
import { RenderingStatus } from "../../../enumerations/Enumerations";
import { RootStore } from "../../../stores/RootStore";
import moment from "moment";

interface ActiveDriversCardProps {
    root?: RootStore;
}

@inject("root") @observer
export class ActiveDriversCard extends React.Component<ActiveDriversCardProps, {}> {
    @observable private renderingStatus: RenderingStatus = RenderingStatus.Waiting;
    private disposer: Lambda;
    private driverSet: Set<string> = new Set<string>();
    @observable private engagementDataPerDriver: Map<string, BeaconEngagement> = new Map<string, BeaconEngagement>();
    private MAX_ENGAGEMENT_SCORE = 5;
    public async componentDidMount() {
        this.fetchEngagementData();
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh,
            () => {
                this.fetchEngagementData();
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    public render() {
        return (
            <Card className="flex-fill">
                <CardBody className="py-4">
                    <Media>
                        <div className="d-inline-block mt-2 mr-3">
                            <Activity className="feather-lg text-warning" />
                        </div>
                        {this.renderBody()}
                    </Media>
                </CardBody>
            </Card>
        );
    }

    private async fetchEngagementData() {
        const beaconStore = this.props.root.beaconStore;
        this.renderingStatus = RenderingStatus.Waiting;
        this.driverSet.clear();
        this.engagementDataPerDriver.clear();
        try {
            const query: BeaconCommissionRequestQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                submittedBy: this.props.root.auth.userUid
            };
            const commissionRequests = await beaconStore.getCommissionBeaconRequestList(query) || [];
            commissionRequests.forEach(async request => {
                this.driverSet.add(request.userId);
                if (!this.engagementDataPerDriver.has(request.userId)) {
                    const engagementData = await BeaconService.getBeaconEngagementData(request.beaconId);
                    if (engagementData.data.response) {
                        this.engagementDataPerDriver.set(request.userId, engagementData.data.response);
                    }
                }
            });
            this.renderingStatus = RenderingStatus.Ready;
        } catch (e) {
            this.renderingStatus = RenderingStatus.Error;
        }
    }

    @computed
    private get activePercentage() {
        var today = new Date();
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const firstDayFormatted = moment(firstDayOfCurrentMonth).format("MM/DD/YYYY");
        const lastDayFormatted = moment(lastDayOfCurrentMonth).format("MM/DD/YYYY");
        const thisMonthKey = `${firstDayFormatted}-${lastDayFormatted}`;
        let numActiveDriver = 0;
        this.engagementDataPerDriver.forEach((engagement) => {
            if (engagement.data) {
                let engagementScore = 0;
                const intervalKeys = Object.keys(engagement.data);
                intervalKeys.forEach(interval => {
                    const activityData = engagement.data[interval];
                    if (interval === thisMonthKey) {
                        const val = (activityData.numberOfDataPoints / (today.getDay() * 1440));
                        engagementScore += (val > 1 ? 1 : val) * this.MAX_ENGAGEMENT_SCORE;
                    } else {
                        const intervalSplit = interval.split("-");
                        const lastDayStrSplit = intervalSplit[1].split("/");
                        const val = (activityData.numberOfDataPoints / (Number(lastDayStrSplit[1]) * 1440));
                        engagementScore += (val > 1 ? 1 : val) * this.MAX_ENGAGEMENT_SCORE;
                    }
                });
                engagementScore = engagementScore / intervalKeys.length;
                if (engagementScore >= 0) {
                    numActiveDriver += 1;
                }
            }
        });
        if (this.engagementDataPerDriver.size !== 0) {
            return Math.round((numActiveDriver / this.driverSet.size) * 100)
        }
        return 0;
    }

    private renderBody() {
        switch (this.renderingStatus) {
            case RenderingStatus.Error:
                return (
                    <Media body>
                        <div className="mb-2">Failed to load. Please try again.</div>
                    </Media>
                ) 
            case RenderingStatus.Waiting:
                return (
                    <Media body>
                        <div className="mb-2 mr-3 mt-2 text-center">
                            <Spinner size="sm" className="mr-2"/>
                        </div> 
                    </Media>
                );
            default:
                return (
                    <Media body>
                        <h3 className="mb-2">{this.activePercentage}%</h3>
                        <div className="mb-0">of your onboarded drivers are active</div>
                    </Media>
                );
        }
    }
}