import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";

export class LoginErrorView extends React.Component {
    public render() {
        return (
            <div className="text-center">
                <h1 className="display-1 font-weight-bold">Ooops</h1>
                <p className="h1">Sign-in error..</p>
                <p className="h2 font-weight-normal mt-3 mb-4">
                    You are not authenticated. Please login.
                </p>
                <Link to="/login">
                    <Button color="primary" size="lg">
                        Login
                    </Button>
                </Link>
            </div>
        );
    }
}
