import * as React from "react";

import { Card, CardHeader, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { inject, observer } from "mobx-react";

import { RootStore } from "../../stores/RootStore";
import { observable } from "mobx";

export interface SettingsNavigationOption {
    key?: string;
    name?: string;
}

interface SettingsNavigationProps{
    root?: RootStore;
    options?: SettingsNavigationOption[];
    onClickNavigationOption?: (key: string) => void;
}

@inject("root") @observer
export class SettingsNavigation extends React.Component<SettingsNavigationProps, {}> {
    @observable private selectedKey;

    constructor(props: SettingsNavigationProps) {
        super(props);
        if (this.props.options && this.props.options.length > 0) {
            this.selectedKey = this.props.options[0].key;
        }
    }

    public render() {
        const options = this.props.options || [];
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h5" className="mb-0">
                        Settings
                    </CardTitle>
                </CardHeader>
                <ListGroup flush>
                    {
                        options.map(option => {
                            return (
                                <ListGroupItem onClick={this.onClickListGroupItem(option.key)} key={option.key} action active={option.key === this.selectedKey}>
                                    {option.name}
                                </ListGroupItem>
                            );
                        })
                    }
                </ListGroup>
            </Card>
        );
    }

    private onClickListGroupItem = (key: string) => () => {
        this.selectedKey = key;
        if (this.props.onClickNavigationOption) {
            this.props.onClickNavigationOption(key);
        }
    }
}