import { Col, Container, Row } from "reactstrap";

import { OnboardingLineChart } from "../../../components/dashboards/overview/OnboardingLineChart";
import { OverviewStatistics } from "../../../components/dashboards/overview/OverviewStatistics";
import { PayoutHistoryCard } from "../../../components/dashboards/overview/PayoutHistoryCard";
import React from "react";
import { RecentActivityCard } from "../../../components/dashboards/overview/RecentActivityCard";
import { RootStore } from "../../../stores/RootStore";
import { inject } from "mobx-react";

@inject("root")
export class Overview extends React.Component<{ root?: RootStore }, {}> {

    public render() {
        return (
            <Container fluid className="p-0">
                <OverviewStatistics />
                <Row>
                    <Col className="d-flex">
                        <OnboardingLineChart />
                    </Col>
                </Row>
                <Row>
                    <Col xl="8">
                        <PayoutHistoryCard />
                    </Col>
                    {/* <Col xl="4">
                        <RecentActivityCard />
                    </Col> */}
                </Row>
            </Container>
        );
    }
}

export default Overview;
