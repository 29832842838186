import * as React from "react";

import { Button, Col, Form, FormGroup, FormText, Input, Label, Row, Spinner } from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { FormSubmissionStatus } from "../common/FormSubmissionStatus";
import { PayoutTaskDetailView } from "./PayoutTaskDetailView";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { TaskDetailView } from "./TaskDetailView";
import TaskService from "../../services/Task.service";
import { observable } from "mobx";

interface ProcessInProgressTaskFormProps extends RouteComponentProps {
    root?: RootStore;
    task: Task;
    dismissCallback?: () => void;
}

@inject("root") @observer
class ProcessInProgressTaskForm extends React.Component<ProcessInProgressTaskFormProps, {}> {
    private cancelator: ApiServiceCancelator;
    @observable submissionMessage: string;
    @observable submissionStatus: RenderingStatus = RenderingStatus.Ready;

    public render() {

        return (
            <div>
                {
                    this.props.task.type === "payout" ? 
                    <div>
                        <PayoutTaskDetailView task={this.props.task} /> 
                        <Form>
                            <FormGroup>
                                <Label>Receipt</Label>
                                <Input type="file" name="file" required/>
                                <FormText color="muted">Attach receipt</FormText>
                            </FormGroup>
                        </Form>
                    </div>
                    : 
                    <TaskDetailView task={this.props.task} />
                }
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="ml-auto text-right mt-n1">
                        <Button color="primary" className="shadow-sm mr-1" onClick={this.processTask("COMPLETED")}>
                            Mark as completed
                        </Button>
                        <Button color="primary" className="shadow-sm mr-1" onClick={this.processTask("PENDING")}>
                            Abandon
                        </Button>
                    </Col>
                </Row>
                <div className="mb-2 mr-3 mt-2 text-center">
                    <Spinner size="sm" hidden={this.submissionStatus !== RenderingStatus.Waiting}/>
                </div>
                <FormSubmissionStatus message={this.submissionMessage} status={this.submissionStatus} />
            </div>
        );
    }

    private extractPhoneNumberFromOnboardTask() {
        const split = this.props.task.description.split("Number ");
        if (split.length > 1) {
            return split[1];
        }
        return "";
    }

    //TODO: get file input, and submit if and only file input is not empty
    private processTask = (status: string) => async () => {
        if (this.cancelator) {
            this.cancelator.cancel();
        }
        this.cancelator = new ApiServiceCancelator();
        this.submissionStatus = RenderingStatus.Waiting;
        try {
            let assignedTo = "";
            if (status === "COMPLETED") {
                assignedTo = this.props.root.auth.userUid;
            } else if (!(this.props.task.type === "payout")) {
                assignedTo = this.props.task.assignedTo;
            }
            if (this.props.task.type === "onboard" && status === "COMPLETED") {
                const phoneNumber = this.extractPhoneNumberFromOnboardTask();
                const result = await this.props.root.usersStore.getUsers({ phoneNumber: phoneNumber }) || [];
                if (result.length > 0) {
                    const user = result[0];
                    if (!user.busId) {
                        this.props.history.push(`/onboard?taskId=${this.props.task.id}&phoneNumber=${phoneNumber}`);
                        return;
                    }
                } else {
                    this.props.history.push(`/onboard?taskId=${this.props.task.id}&phoneNumber=${phoneNumber}`);
                    return;
                }
            }
            await TaskService.updateTask( {
                taskId: this.props.task.id,
                status: status,
                assignedTo: assignedTo
            }, this.cancelator);
            
            this.submissionStatus = RenderingStatus.Ready;
            if (this.props.dismissCallback) {
                this.props.dismissCallback();
            }
            let message = "";
            switch (status) {
                case "COMPLETED" :
                    message = "Task has been successfully marked as being completed.";
                    break;
                default:
                    message = "Task has been successfully being abandoned.";
                    break;
            }
            this.props.root.toastStore.showNotif({
                title: "",
                message: message,
                duration: ToastDuration.Normal,
                toastType: ToastMessageType.Success
            });
        } catch (e) {
            console.log(e);
            this.submissionStatus = RenderingStatus.Error;
            this.submissionMessage = "Failed to update task status."
        }
    }
}

const wrapper = withRouter(ProcessInProgressTaskForm);
export { wrapper as ProcessInProgressTaskForm };