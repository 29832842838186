import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import React, { ChangeEvent } from "react";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";

import { RootStore } from "../../stores/RootStore";
import { RouteComponentProps } from "react-router-dom";
import { inject } from "mobx-react";

interface SignInProps extends RouteComponentProps {
    root?: RootStore;
}

@inject("root")
export class SignIn extends React.Component<SignInProps, {}> {
    private email: string = "";
    private password: string = "";
    
    public render() {
        return (
            <React.Fragment>
                <div className="text-center mt-4">
                    <h2>Welcome</h2>
                    <p className="lead">Sign in to continue</p>
                </div>
                <Card>
                    <CardBody>
                        <div className="m-sm-4">
                            <Form>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input
                                        bsSize="lg"
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        onChange={this.onEmailChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input
                                        bsSize="lg"
                                        type="password"
                                        name="password"
                                        placeholder="Enter your password"
                                        onChange={this.onPasswordChange}
                                    />
                                </FormGroup>
                                <div className="text-center mt-3">
                                        <Button color="primary" size="lg" onClick={this.onSignin}>
                                            Sign in
                                        </Button>
                                </div>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

    private onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {this.email = event.target.value;}
    private onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {this.password = event.target.value;}
    private onSignin = async () => {
        try {
            await this.props.root.auth.loginWithCredentials(this.email, this.password); this.email = ""; this.password = "";
            this.props.history.push("/dashboard");
        } catch (e) {
            this.props.root.toastStore.showNotif({
                title: "Login failed.",
                message: "Invalid Credentials",
                duration: ToastDuration.Normal,
                toastType: ToastMessageType.Error
            });
        }
        
    }
}

