import "firebase/auth";

import firebase from "firebase/app";

const prodConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_PROD,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
};

const devConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
};

const config = process.env.NODE_ENV === "production"
    ? prodConfig
    : devConfig;
    
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const Auth = firebase.auth();