import * as React from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { observable } from "mobx";
import { observer } from "mobx-react";

interface PaginationWrapperProps {
    pagesCount: number;
    handlePageClick?: (currentPage: number) => void;
}

@observer
export class PaginationWrapper extends React.Component<PaginationWrapperProps, {}> {
    @observable private currentPage: number = 0;
    private pagesCount: number = 0;

    constructor(props: PaginationWrapperProps) {
        super(props);
        this.pagesCount = this.props.pagesCount;
    }

    public render() {
        return (
            <div className="pagination-wrapper">
                <Pagination>
                    <PaginationItem disabled={this.currentPage <= 0}>
                        <PaginationLink
                            onClick={e => this.handleClick(e, this.currentPage - 1)}
                            previous
                            href="#"
                        />  
                    </PaginationItem>

                    {[...Array(this.pagesCount)].map((page, i) => 
                        <PaginationItem active={i === this.currentPage} key={i}>
                            <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                            {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    )}

                    <PaginationItem disabled={this.currentPage >= this.pagesCount - 1}>
                        <PaginationLink
                            onClick={e => this.handleClick(e, this.currentPage + 1)}
                            next
                            href="#"
                        />
                    </PaginationItem>
                    
                </Pagination>
            </div>
            
        );
    }

    private handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) {
        event.preventDefault();
        this.currentPage = index;
        if (this.props.handlePageClick) {
            this.props.handlePageClick(this.currentPage);
        }
    }
}