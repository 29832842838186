import * as React from "react";

import { Button, Spinner } from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { FormSubmissionStatus } from "../common/FormSubmissionStatus";
import { PayoutTaskDetailView } from "./PayoutTaskDetailView";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { TaskDetailView } from "./TaskDetailView";
import TaskService from "../../services/Task.service";
import { observable } from "mobx";

interface ProcessPendingTaskViewProps extends RouteComponentProps {
    root?: RootStore;
    task: Task;
    dismissCallback?: () => void;
}

@inject("root") @observer
class ProcessPendingTaskView extends React.Component<ProcessPendingTaskViewProps, {}> {
    private cancelator: ApiServiceCancelator;
    @observable submissionMessage: string;
    @observable submissionStatus: RenderingStatus = RenderingStatus.Ready;
    
    public render() {
        return (
            <div>
                {
                    this.props.task.type === "payout" ? 
                    <PayoutTaskDetailView task={this.props.task} /> : <TaskDetailView task={this.props.task} />
                }
                <div className="text-center">
                    <Button color="primary" onClick={this.startProcessingTask}>Get started</Button>
                </div>
                <div className="mb-2 mr-3 mt-2 text-center">
                    <Spinner size="sm" hidden={this.submissionStatus !== RenderingStatus.Waiting}/>
                </div>
                <FormSubmissionStatus message={this.submissionMessage} status={this.submissionStatus} />
            </div>
        );

    }

    private extractPhoneNumberFromOnboardTask() {
        const split = this.props.task.description.split("Number ");
        if (split.length > 1) {
            return split[1];
        }
        return "";
    }
    

    private startProcessingTask = async () => {
        if (this.cancelator) {
            this.cancelator.cancel();
        }
        this.cancelator = new ApiServiceCancelator();
        this.submissionStatus = RenderingStatus.Waiting;
        try {
            await TaskService.updateTask( {
                taskId: this.props.task.id,
                status: "INPROGRESS",
                assignedTo: this.props.root.auth.userUid
            }, this.cancelator);
            if (this.props.task.type === "onboard") {
                this.props.history.push(`/onboard?taskId=${this.props.task.id}&phoneNumber=${this.extractPhoneNumberFromOnboardTask()}`);
            }
            this.submissionStatus = RenderingStatus.Ready;
            if (this.props.dismissCallback) {
                this.props.dismissCallback();
            }
            this.props.root.toastStore.showNotif({
                title: "",
                message: "Task has been successfully marked as being in progress.",
                duration: ToastDuration.Normal,
                toastType: ToastMessageType.Success
            });
        } catch (e) {
            console.log(e);
            this.submissionStatus = RenderingStatus.Error;
            this.submissionMessage = "Failed to update task status."
        }
    }
}

const wrapper = withRouter(ProcessPendingTaskView);
export { wrapper as ProcessPendingTaskView };