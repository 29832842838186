import { computed, observable } from "mobx";

import { Auth } from "../firebase/Auth";
import { RenderingStatus } from "../enumerations/Enumerations";
import { RootStore } from "./RootStore";
import { auth } from "firebase";

export class AuthStore {
    private static accessTokenKey = "agent_app_access_token";
    @observable public isLoggedIn: boolean = false;
    private currentUser: firebase.User;
    private root: RootStore;
    private token: string;
    private uid: string;
    @observable private loginStatus: RenderingStatus = RenderingStatus.Waiting;

    constructor(root: RootStore) {
        this.root = root;
        auth().onAuthStateChanged(async (user) => {
            if (user) {
                this.uid = user.uid;
                this.currentUser = user;
                await this.login();
                console.log("Authenticated");
            } else {
                console.log('Not authenticated');
                this.loginStatus = RenderingStatus.Error;
            }
        });
    }

    @computed public get LoginStatus() {
        return this.loginStatus;
    }

    public async loginWithCredentials(email: string, password: string) {
        await Auth.setPersistence(auth.Auth.Persistence.LOCAL);
        await Auth.signInWithEmailAndPassword(email, password);
    }

    public async login() {
        try {
            this.loginStatus = RenderingStatus.Waiting;
            await this.getAuthToken();
            if (this.token) {
                this.isLoggedIn = true;
                console.log(this.token);
                await this.actionsAfterSuccessfulLogin();
                this.loginStatus = RenderingStatus.Ready;
            }
        } catch (error) {
            console.log(error);
            this.loginStatus = RenderingStatus.Error;
        }
    }

    private async actionsAfterSuccessfulLogin() {
        await this.root.myInfoStore.loadUserProfile();
    }

    private saveToken(accessToken: string) {
        this.token = accessToken;
        localStorage.setItem(AuthStore.accessTokenKey, accessToken);
    }

    public async getAuthToken() {
        var token: string;
        try {
            if (this.currentUser) {
                token = await Auth.currentUser.getIdToken(false);
                this.saveToken(token);
            }
        } catch (error) {
            this.token = null;
            throw error;
        }
        return token;
    }

    private clearToken(): void {
        this.token = null;
        localStorage.removeItem(AuthStore.accessTokenKey);
    }

    public get userUid() {
        return this.uid;
    }
    
    public logout() {
        try {
            Auth.signOut();
            this.clearToken();
            this.currentUser = null;
            this.isLoggedIn = false;
        } catch (ex) {
            console.log(ex);
        }
    }

    public async isCurrentPasswordValid(password: string) {
        const credential = auth.EmailAuthProvider.credential(this.currentUser.email, password);
        try {
            await this.currentUser.reauthenticateWithCredential(credential);
            return true;
        } catch (e) {
            console.log(e);
        }
        return false;
    }

    public async updatePassword(newPassword: string) {
        await this.currentUser.updatePassword(newPassword);
    }

}