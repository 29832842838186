import * as React from "react";

import { Button, Card, CardBody, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Spinner } from "reactstrap";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { Search } from "react-feather";
import Select from "react-select";
import { components } from "react-select";
import { observable } from "mobx";

interface BeaconLookupAndLineSelectionFormProps {
    root?: RootStore;
    commissionRequest?: BeaconCommissionRequest;
    submissionStatus?: SubmissionStatus;
}

export interface ReactSelectOption {
    value?: string;
    label?: string;
}

@inject("root") @observer
export class BeaconLookupAndLineSelectionForm extends React.Component<BeaconLookupAndLineSelectionFormProps, {}> {
    private cancelator: ApiServiceCancelator;
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Ready;
    @observable private productIdentifier: string = "";
    @observable private beaconId: string = "";

    private isValidNewOption = (inputValue, selectValue) =>
        inputValue.length > 0 && selectValue.length < 5;

    public render() {
        return (
            <Card>
                <CardBody>
                    <Form>
                        <FormGroup>
                            <Label>Beacon Product Identifier</Label>
                            <InputGroup className="mb-3">
                                <Input type="text" placeholder="IBKS..." onChange={this.onChangeProductIdentifier}/>
                                <InputGroupAddon addonType="append" color="primary">
                                    <Button onClick={this.onClickSearch} disabled={!this.productIdentifier}>
                                        <Search className="feather" />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Spinner size="sm" hidden={this.loadingStatus !== RenderingStatus.Waiting}/>
                        </FormGroup>
                        {
                            this.beaconId && this.beaconId !== "undefined" && 
                            <FormGroup>
                                <Label>Bus ID</Label>
                                <Input type="text" name="readonlyInput" id="user" placeholder={this.beaconId} readOnly />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label>Bus routes often used</Label>
                            <Select
                                className="react-select-container"
                                placeholder="Search..."
                                components={{ Menu }}
                                isMulti
                                isValidNewOption={this.isValidNewOption}
                                options={this.props.root.linesOptions}
                                onChange={this.onChangeBusRouteSelection}
                            />
                        </FormGroup>
                    </Form>
                    {/* <CardText>
                        Using the iBKS Config tool, configure a free Eddystone slot as EID and register the beacon on Google Beacon Registry with given the generated UID.
                    </CardText> */}
                </CardBody>
            </Card>
        );
    }

    private onChangeBusRouteSelection = (values: ReactSelectOption[]) => {
        const lineIds = [];
        if (values) {
            values.forEach((option) => {
                lineIds.push(option.value);
            });
        }
        this.props.commissionRequest.lines = lineIds;
    }

    private onChangeProductIdentifier = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.productIdentifier = event.target.value;
    }

    private onClickSearch = async () => {
        if (this.cancelator) {
            this.cancelator.cancel();
        }
        this.cancelator = new ApiServiceCancelator();
        const beaconStore = this.props.root.beaconStore;
        const query: BeaconRegistrationInfoQuery = {
            productIdentifier: this.productIdentifier
        };
        this.loadingStatus = RenderingStatus.Waiting;
        try {
            await beaconStore.updateBeaconRegistrationInfoListCache(query);
            const result = await beaconStore.getBeaconRegistrationInfoList(query) || [];
            if (result.length > 0) {
                this.beaconId = result[0].beaconId;
                this.props.submissionStatus.loadingStatus = RenderingStatus.Ready;
                this.props.submissionStatus.message = "";
                this.props.commissionRequest.beaconId = result[0].beaconId;
                this.loadingStatus = RenderingStatus.Ready;
                return;
            } 
        } catch (e) {
            console.log(e);
        }
        this.loadingStatus = RenderingStatus.Error;
        this.props.submissionStatus.loadingStatus = RenderingStatus.Error;
        this.props.submissionStatus.message = `Beacon with product identifier: ${this.productIdentifier} not found. Please try again.`;
        this.beaconId = "undefined";
    }
}

const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };
