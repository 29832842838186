import * as React from "react";

import { Card, CardBody, Media, Spinner } from "reactstrap";
import { Lambda, computed, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../../stores/AppSettingStore";
import { DollarSign } from "react-feather";
import { RenderingStatus } from "../../../enumerations/Enumerations";
import { RootStore } from "../../../stores/RootStore";

@inject("root") @observer
export class TotalCompensationCard extends React.Component<{ root?: RootStore }, {}> {
    @observable private compensationList: CashActivity[] = [];
    @observable private renderingStatus: RenderingStatus = RenderingStatus.Waiting;
    private disposer: Lambda;

    public async componentDidMount() {
        this.fetchCompensationList();
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh || this.props.root.cashFlowStore.willRefresh,
            () => {
                this.fetchCompensationList();
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    public render() {
        return (
            <Card className="flex-fill">
                <CardBody className="py-4">
                    <Media>
                        <div className="d-inline-block mt-2 mr-3">
                            <DollarSign className="feather-lg text-info" />
                        </div>
                        {this.renderBody()}
                    </Media>
                </CardBody>
            </Card>
        )
    }

    private async fetchCompensationList() {
        const cashFlowStore = this.props.root.cashFlowStore;
        this.renderingStatus = RenderingStatus.Waiting
        try {
            const query: CashActivityQuery = {
                collection: "costs",
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                status: "COMPLETED",
                settlementTo: this.props.root.auth.userUid
            };
            this.compensationList = await cashFlowStore.getCashActivityList(query);
            this.renderingStatus = RenderingStatus.Ready
        } catch (err) {
            console.log(err);
            this.renderingStatus = RenderingStatus.Error;
        }
    }

    @computed
    private get totalCompensation() {
        var amount = 0;
        this.compensationList.forEach((cashActivity) => {
            amount += Math.abs(cashActivity.amount);
        });
        return amount;
    }

    public renderBody() {
        switch (this.renderingStatus) {
            case RenderingStatus.Error:
                return (
                    <Media body>
                        <div className="mb-2">Failed to load. Please try again.</div>
                    </Media>
                ) 
            case RenderingStatus.Waiting:
                return (
                    <Media body>
                        <div className="mb-2 mr-3 mt-2 text-center">
                            <Spinner size="sm" className="mr-2"/>
                        </div> 
                    </Media>
                );
            default:
                return (
                    <Media body>
                        <h3 className="mb-2">${this.totalCompensation}</h3>
                        <div className="mb-0">Total Compensation</div>
                    </Media>
                );
        }
    }
}