import * as React from "react";

import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Spinner } from "reactstrap";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { inject, observer } from "mobx-react";

import { BeaconCreateUserForm } from "./BeaconCreateUserForm";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { Search } from "react-feather";
import { observable } from "mobx";

interface BeaconUserLookupProps {
    root?: RootStore;
    commissionRequest?: BeaconCommissionRequest;
    submissionStatus?: SubmissionStatus;
    dismissHandler?: () => void;
}

@inject("root") @observer
export class BeaconUserLookup extends React.Component<BeaconUserLookupProps, {}> {
    @observable private loadingStatus = RenderingStatus.Ready;
    @observable private searchNumString: string = "";
    
    public componentDidMount() {
        this.searchNumString = this.props.commissionRequest.phoneNumber;
    }

    public render() {
        const userId = this.props.commissionRequest.userId;
        const user = this.props.root.usersStore.userIdMap.get(userId);
        return (
            <Card>
                <CardBody>
                    <Form>
                        <FormGroup>
                            <Label>User lookup</Label>
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">+233</InputGroupAddon>
                                <Input type="number" placeholder="Phone number" value={this.searchNumString} onChange={this.onSearchNumChange} />
                                <InputGroupAddon addonType="append" color="primary">
                                    <Button onClick={this.onClickSearchButton} disabled={!this.searchNumString}>
                                        <Search className="feather" />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Spinner size="sm" hidden={this.loadingStatus !== RenderingStatus.Waiting}/>
                        </FormGroup>
                        {
                            userId && userId !== "undefined" &&
                            <FormGroup>
                                <Label>User ID</Label>
                                <Input
                                    type="text"
                                    name="readonlyInput"
                                    id="user"
                                    placeholder={userId}
                                    readOnly
                                />
                            </FormGroup>
                        }
                        {
                            userId && userId !== "undefined" &&
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>First name</Label>
                                        <Input type="text" name="firstName" placeholder={user ? user.firstName : ""} readOnly/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Last name</Label>
                                        <Input type="text" name="lastName" placeholder={user ? user.lastName : ""} readOnly/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </Form>
                    {
                        userId === "undefined" && 
                        <BeaconCreateUserForm commissionRequest={this.props.commissionRequest} submissionStatus={this.props.submissionStatus} />
                    }
                </CardBody>
            </Card>
        );
    }

    private onSearchNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.searchNumString = event.target.value;
    }

    private onClickSearchButton = async (event: any) => {
        this.loadingStatus = RenderingStatus.Waiting;
        const phoneNumber = this.searchNumString.charAt(0) === "0" ? `+233${this.searchNumString.slice(1)}` : `+233${this.searchNumString}`;
        try {
            const result = await this.props.root.usersStore.getUsers({ phoneNumber: phoneNumber }) || [];
            if (result.length > 0) {
                const user = result[0];
                this.loadingStatus = RenderingStatus.Ready;
                this.props.submissionStatus.loadingStatus = RenderingStatus.Ready;
                this.props.submissionStatus.message = "";
                this.props.commissionRequest.userId = user.userId;
                this.props.commissionRequest.phoneNumber = phoneNumber;
                if (user.busId && this.props.dismissHandler) {
                    this.props.submissionStatus.message = `User with number ${phoneNumber} has already been onboarded into the system.`;
                    this.props.dismissHandler();
                }
                return;
            }
        } catch (e) {
            console.log(e);
        }
        this.loadingStatus = RenderingStatus.Error;
        this.props.submissionStatus.loadingStatus = RenderingStatus.Error;
        this.props.submissionStatus.message = `User with phone number: ${phoneNumber} not found. Fill out form to create user.`;
        this.props.commissionRequest.userId = "undefined";
    }
}