import * as React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Spinner,
} from "reactstrap";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { RefreshCw } from "react-feather";
import { RootStore } from "../../stores/RootStore";
import { observable } from "mobx";

interface LaneProps {
    root?: RootStore;
    name?: string;
    description?: string;
    onRefresh?: (cancelator: ApiServiceCancelator) => Promise<void>;
    refreshFailMessage?: string;
}

@inject("root") @observer
export class Lane extends React.Component<LaneProps, {}> {
    @observable private cancelator: ApiServiceCancelator;
    @observable private isLoading: boolean = false;

    public render() {
        const { name, children, description } = this.props;
        return (
            <Card>
                <CardHeader>
                    <div className="card-actions float-right">
                        <Spinner size="sm" className="mr-2" hidden={!this.isLoading}/>
                        <RefreshCw onClick={this.refresh} />
                    </div>
                    <CardTitle tag="h5">{name}</CardTitle>
                    <h6 className="card-subtitle text-muted">
                        {description}
                    </h6>
                </CardHeader>
                <CardBody className="p-3 scroll">
                    <div>{children}</div>
                </CardBody>
            </Card>
        );
    }

    private refresh = async () => {
        if (this.cancelator) {
            this.cancelator.cancel();
        }
        this.cancelator = new ApiServiceCancelator();
        if (this.props.onRefresh) {
            this.isLoading = true;
            try {
                await this.props.onRefresh(this.cancelator);
            } catch (e) {
                if (this.props.refreshFailMessage) {
                    this.props.root.toastStore.showNotif({
                        title: "Error",
                        message: this.props.refreshFailMessage,
                        duration: ToastDuration.Normal,
                        toastType: ToastMessageType.Success
                    })
                }
            }
            this.isLoading = false;
        }
    }
}