import {
    BarChart2,
    BookOpen as BookOpenIcon,
    Cast,
    List as ListIcon,
    Settings
} from "react-feather";

import { OnboardDriver } from "../pages/onboarding";
import Overview from "../pages/dashboards/Overview";
import { Tasks } from "../pages/tasks/Tasks";
import { UserSettings } from "../pages/settings";

const dashboardRoutes: RouteCategory = {
    path: "/dashboard",
    title: "Dashboard",
    header: "Main",
    icon: BarChart2,
    containsHome: true,
    standalone: true,
    children: [
        {
            path: "/dashboard",
            name: "Dashboard",
            app: Overview
        }
    ]
};

const tasksRoutes: RouteCategory = {
    path: "/tasks",
    title: "Tasks",
    icon: ListIcon,
    standalone: true,
    children: [
        {
            path: "/tasks",
            name: "Tasks",
            app: Tasks
        }
    ]
};

const beaconRoutes: RouteCategory = {
    path: "/onboard",
    title: "Onboarding",
    icon: Cast,
    standalone: true,
    children: [
        {
            path: "/onboard",
            name: "Onboard",
            app: OnboardDriver
        }
    ]
}

const settingRoutes: RouteCategory = {
    path: "/settings",
    title: "Settings",
    icon: Settings,
    standalone: true,
    children: [
        {
            path: "/settings",
            name: "Settings",
            app: UserSettings
        }
    ]
}

const routes = [
    dashboardRoutes,
    beaconRoutes,
    tasksRoutes,
    settingRoutes
];

export { routes as CoreRoutes};