import * as React from "react";

import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Spinner } from "reactstrap";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import UsersApiService from "../../services/Users.service";

interface BeaconCreateUserFormProps {
    root?: RootStore;
    commissionRequest?: BeaconCommissionRequest;
    submissionStatus?: SubmissionStatus;
}

@inject("root") @observer
export class BeaconCreateUserForm extends React.Component<BeaconCreateUserFormProps, {}> {
    private cancelator: ApiServiceCancelator;
    @observable private createUserRequest: CreateUserRequest;
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Ready;
    constructor(props: BeaconCreateUserFormProps) {
        super(props);
        this.createUserRequest = {
            phoneNumber: "",
            firstName: "",
            lastName: "",
            email: ""
        };
    }

    @computed
    private get isCreateFormValid() {
        return !!this.createUserRequest.firstName && !!this.createUserRequest.lastName && !!this.createUserRequest.phoneNumber 
    }

    public render() {

        return (
            <Card>
                <CardHeader tag="h5">
                    Create user
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup required>
                                    <Label>First name</Label>
                                    <Input type="text" name="firstName" placeholder="" onChange={this.onChangeFirstName} required/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup required>
                                    <Label>Last name</Label>
                                    <Input type="text" name="lastName" placeholder="" onChange={this.onChangeLastName} required/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Phone number</Label>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">+233</InputGroupAddon>
                                        <Input type="number" placeholder="" onChange={this.onChangePhoneNumber} required/>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Email (optional)</Label>
                                    <Input type="email" name="email" placeholder="" onChange={this.onChangeEmail} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.createUser} disabled={!this.isCreateFormValid}>Create</Button>
                        <FormGroup className="text-center">
                            <Spinner size="sm" hidden={this.loadingStatus !== RenderingStatus.Waiting}/>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        );
    }

    private onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.createUserRequest.phoneNumber = event.target.value;
    }

    private onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.createUserRequest.firstName = event.target.value;
    }

    private onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.createUserRequest.lastName = event.target.value;
    }

    private onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.createUserRequest.email = event.target.value;
    }

    private createUser = async () => {
        this.loadingStatus = RenderingStatus.Waiting;
        if (this.cancelator) {
            this.cancelator.cancel();
        }
        this.createUserRequest.phoneNumber = this.createUserRequest.phoneNumber.charAt(0) === "0" ? 
            `+233${this.createUserRequest.phoneNumber.slice(1)}` : `+233${this.createUserRequest.phoneNumber}`;
        this.cancelator = new ApiServiceCancelator();
        try {
            const apiResponse = await UsersApiService.createUser(this.createUserRequest, this.cancelator);
            const user = apiResponse.data.response;
            this.props.root.usersStore.phoneNumberMap.set(user.phoneNumber, user);
            this.props.root.usersStore.userIdMap.set(user.userId, user);
            this.props.commissionRequest.userId = user.userId;
            this.loadingStatus = RenderingStatus.Ready;
            this.props.submissionStatus.loadingStatus = RenderingStatus.Ready;
            this.props.submissionStatus.message = "New user successfully created. Please proceed to next step.";
        } catch (e) {
            this.loadingStatus = RenderingStatus.Error;
            this.props.submissionStatus.loadingStatus = RenderingStatus.Error;
            this.props.submissionStatus.message = "Error occurred creating new user. Please try again";
            console.log(e);
        }
    }
}