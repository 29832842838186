export default class Endpoints {
    public static USERS_ENDPOINT = "/users";
    public static GRAPH_API_ERRORS_ENDPOINT = "/logs/itinerarygenerationfail";
    public static ITINERARY_FEEDBACK_ENDPOINT = "/feedback/itinerary";
    public static LOCATION_LOG_ENDPOINT = "/logs/location";
    public static NO_NEARBY_STOP_ENDPOINT = "/logs/missingstops";
    public static SEARCH_LOG_ENDPOINT = "/logs/search";
    public static UPLOAD_LOG_ENDPOINT = "/logs/uploadhistory";
    public static CASH_ACTIVITY_ENDPOINT = "/cashactivities";
    public static TASKS_ENDPOINT = "/tasks";
    public static CROWDSOURCED_LOCATION_ENDPOINT = "/logs/location/crowdsourced";
    public static REWARDS = "/rewards";
    public static PUBLIC_BEACON_INFO_ENDPOINT = "/beacons";
    public static BEACON_REGISTRATION_INFO_ENDPOINT = "/beacons/registrationInfo";
    public static GENERATE_NEW_BEACON_UID = "/generateBeaconUID";
    public static COMMISSION_BEACON_ENDPOINT = "/beacons/commission";
    public static GEOFENCE_REQUESTS_ENDPOINT = "/geofence/requests";
    public static BEACON_ENGAGEMENT_ENDPOINT = "/beacon-engagement";
}