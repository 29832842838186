import { Col, Row } from "reactstrap";

import { ActiveDriversCard } from "./ActiveDriversCard";
import { OnboardedUserCountCard } from "./OnboardedUserCountCard";
import { PendingTasksCard } from "./PendingTasksCard";
import React from "react";
import { RootStore } from "../../../stores/RootStore";
import { TotalCompensationCard } from "./TotalCompensationCard";
import { inject } from "mobx-react";

@inject("root")
export class OverviewStatistics extends React.Component<{root?: RootStore}> {
    public render() {
        return (
            <Row>
                <Col md="6" xl className="d-xxl-flex">
                    <OnboardedUserCountCard/>
                </Col>
                <Col md="6" xl className="d-xxl-flex">
                    <ActiveDriversCard/>
                </Col>
                <Col md="6" xl className="d-xxl-flex">
                    <PendingTasksCard/>
                </Col>
                <Col md="6" xl className="d-xxl-flex">
                    <TotalCompensationCard/>
                </Col>
            </Row>
        );
    }
}

