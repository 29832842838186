import { ApiService } from "../apiservices/ApiService";
import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import Endpoints from "./Endpoints";

export default class CrowdsourcedLocationService {

    public static getCrowdsourcedLocationsGivenQuery = (query: CrowdsourcedLocationQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: CrowdsourcedLocation[] }}> => {
        var url = Endpoints.CROWDSOURCED_LOCATION_ENDPOINT + `?busId=${query.busId || ""}&country=${query.country || ""}&source=${query.source || ""}&senderId=${query.senderId || ""}`;
        if (query.startTime) {
            url += `&startTime=${query.startTime}`;
        }
        if (query.endTime) {
            url += `&endTime=${query.endTime}`;
        }
        return ApiService.request({
            url: url,
            method: "get",
            data: query
        }, cancelator);
    }
}