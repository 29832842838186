import { RefreshStore } from "./RefreshStore";
import ghFlag from "../assets/img/flags/gh.png";
import { settings } from "../config/app.settings";

export class AppSettingStore extends RefreshStore {

    public static SELECTED_COUNTRY_CACHE_KEY = "AGENT_SELECTED_COUNTRY";
    private currentCountry: string;
    private settings: { [id: string]: string } = {};
    private supportedCountries: Map<string, CountryInfo> = new Map();

    constructor() {
        super();
        this.settings = process.env.NODE_ENV === "development" ? settings.development : settings.production;
        this.supportedCountries.set("GH", {
            phoneCode: "+233",
            name: "Ghana",
            iso: "GH",
            flagImg: ghFlag
        });
        if (!localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY)) {
            localStorage.setItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY, "GH");
        }
        this.currentCountry = localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY);
    }

    public get Setting() {
        return this.settings;
    }

    public get selectedCountry() {
        return this.currentCountry;
    }

    public get supportedCountriesMap() {
        return this.supportedCountries;
    }
}