import * as React from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Provider, inject, observer } from "mobx-react";
import { Redirect, Route, RouteComponentProps, BrowserRouter as Router, Switch, withRouter } from "react-router-dom";
import { Root, RootStore } from "./stores/RootStore";
import { THEME_KEY, toggleTheme } from "./services/Theme.service";
import { observable, when } from 'mobx';

import { ApiService } from "./apiservices/ApiService";
import { AuthLayout } from './components/layouts/AuthLayout';
import { AuthenticatedRoute } from "./components/auth/AuthenticatedRoute";
import { LoginErrorView } from "./pages/auth/LoginErrorView";
import ScrollToTop  from "./components/nav/ScrollToTop";
import { SignIn } from "./pages/auth/SignIn";
import { CoreRoutes as routes } from "./stores/RouteConfig";

interface MainAppProps extends RouteComponentProps {
    root?: RootStore;
}

@inject("root") @observer
class MainApp extends React.Component<MainAppProps, {}> {
    @observable private popupVisibility: boolean = false;

    public componentDidMount() {
        when(() => !!this.props.root.myInfoStore.profile, () => {
            const profile = this.props.root.myInfoStore.profile;
            if (!profile.firstName || !profile.lastName || !profile.phoneNumber) {
                this.togglePopupVisibility();
            }
        });
    }

    public generateChildRoutes() {   
        return routes.map((category: RouteCategory, index: number) => {
            if (category.standalone) {
                const Component = category.children[0].app;
                
                return (
                    <Route
                        key={index}
                        path={category.path}
                        exact
                        render={this.renderPageApp(Component)}
                    />
                );
            }
            return (
                category.children.map((routeInfo: RouteInfo, index) => {
                    const Component = routeInfo.app;
                    return (
                        <Route
                            key={index}
                            path={routeInfo.path}
                            exact
                            render={this.renderPageApp(Component)}
                        />
                    );
                })
            );
        });
    }

    public render() {
        return (
            <div>
                {this.renderCompleteProfilePopup()}
                <ScrollToTop>
                    <Switch>
                        {this.generateChildRoutes()}
                        <Route path ="/login" render={props => (
                                    <AuthLayout>
                                        <SignIn {...props} />
                                    </AuthLayout>
                                )}/>
                        {/* <Redirect to="/login" /> */}
                        <Route
                            render={() => (
                                <AuthLayout>
                                    <LoginErrorView />
                                </AuthLayout>
                            )}
                        />
                    </Switch>
                </ScrollToTop>
            </div>
            
        );
    }

    private readonly renderPageApp = (App: React.ComponentClass) => (props: RouteComponentProps<void>) => {
        return (
            <AuthenticatedRoute>
                    <App {...props} />
            </AuthenticatedRoute>
        )
    }

    private togglePopupVisibility = () => {
        this.popupVisibility = !this.popupVisibility;
    }

    private redirectToSettingsPage = () =>{
        this.props.history.push("/settings");
        this.togglePopupVisibility();
    }

    private renderCompleteProfilePopup() {
        return (
            <Modal
                isOpen={this.popupVisibility}
                toggle={this.togglePopupVisibility}
                size={"sm"}
                centered
            >
                <ModalHeader toggle={this.togglePopupVisibility}> 
                    Incomplete profile
                </ModalHeader>
                <ModalBody className="text-center m-3">
                    Please complete your profile.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.redirectToSettingsPage}>
                        Complete profile
                    </Button>
                    <Button color="primary" onClick={this.togglePopupVisibility}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const wrapper = withRouter(MainApp)
export { wrapper as MainApp };

@observer
export class App extends React.Component<{}, {}> {
    private readonly root: RootStore;
    @observable private initialized = false;

    constructor(props: {}) {
        super(props);
        this.root = Root;
    }

    public componentDidMount() {
        this.init();
        this.setThemeFromCache();
    }

    private init() {
        this.root.init();
        ApiService.initialize();
        this.initialized = true;
    }

    private setThemeFromCache() {
        const theme = localStorage.getItem(THEME_KEY);
        if (theme) {
            toggleTheme(theme);
        }
    }

    public render() {
        return this.initialized ? (
            <Provider root={this.root}>
                <Router>
                    <div> <Route path="/" component={MainApp} /> </div>
                </Router>
            </Provider>
        ) : null;
    }
}
