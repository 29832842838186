import { Badge, Button } from "reactstrap";
import { inject, observer } from "mobx-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import { RootStore } from "../../stores/RootStore";
import { Settings as SettingsIcon } from "react-feather";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { observable } from "mobx";
import screenshotClassic from "../../assets/img/screenshots/theme-classic-small.png";
import screenshotCorporate from "../../assets/img/screenshots/theme-corporate-small.png";
import screenshotModern from "../../assets/img/screenshots/theme-modern-small.png";

interface LayoutSettingsProps {
    root?: RootStore;
}

@inject("root") @observer
export class LayoutSettings extends React.Component<LayoutSettingsProps, {}> {
    @observable private isOpen: boolean = false;

    private toggleSettingsSidebar = () => {
        this.isOpen = !this.isOpen;
    }

    public render() {
        return (
            <div className={"settings " + (this.isOpen ? "open" : "")}>
                <div className="settings-toggle" onClick={this.toggleSettingsSidebar}>
                    <SettingsIcon />
                </div>

                <div className="settings-panel">
                    <div className="settings-content">
                        <PerfectScrollbar>
                            <div className="settings-title">
                                <Button close onClick={this.toggleSettingsSidebar} />

                                <h4>Settings</h4>
                            </div>

                            <div className="settings-section">
                                <small className="d-block text-uppercase font-weight-bold text-muted mb-2">
                                    Layouts
                                </small>

                                <ul className="settings-layouts">
                                    <li>
                                        <span
                                            className="settings-layouts-item"
                                            onClick=
                                                {() => 
                                                    {
                                                        this.props.root.toggleSidebarStickiness();
                                                        this.toggleSettingsSidebar()
                                                    }
                                                }
                                        >
                                            {this.props.root.isSidebarSticky ? "Static Sidebar" : "Sticky Sidebar"}
                                            <Badge tag="small" className="float-right mt-1">
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </Badge>
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            className="settings-layouts-item"
                                            onClick={() =>
                                                {
                                                    this.toggleSettingsSidebar();
                                                    this.props.root.toggleSidebar();
                                                }
                                            }
                                        >
                                            {this.props.root.isSidebarOpened ? "Collapsed Sidebar" : "Visible Sidebar"}
                                            <Badge tag="small" className="float-right mt-1">
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </Badge>
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div className="settings-section">
                                <small className="d-block text-uppercase font-weight-bold text-muted mb-2">
                                    Themes
                                </small>

                                <div
                                    className="settings-theme"
                                    onClick={() => 
                                        {
                                            this.props.root.enableClassicTheme();
                                            this.toggleSettingsSidebar();
                                        }
                                    }
                                >
                                    <img
                                        src={screenshotClassic}
                                        className="img-fluid"
                                        alt="Classic"
                                    />
                                    <span className="d-inline-block mt-1 text-muted">
                                        Classic
                                    </span>
                                </div>

                                <div
                                    className="settings-theme"
                                    onClick={() =>
                                        {
                                            this.props.root.enableCorporateTheme();
                                            this.toggleSettingsSidebar();
                                        }
                                    }
                                >
                                    <img
                                        src={screenshotCorporate}
                                        className="img-fluid"
                                        alt="Corporate"
                                    />
                                    <span className="d-inline-block mt-1 text-muted">
                                        Corporate
                                    </span>
                                </div>

                                <div
                                    className="settings-theme"
                                    onClick={() =>
                                        {
                                            this.props.root.enableModernTheme();
                                            this.toggleSettingsSidebar();
                                        }
                                    }
                                >
                                    <img
                                        src={screenshotModern}
                                        className="img-fluid"
                                        alt="Modern"
                                    />
                                    <span className="d-inline-block mt-1 text-muted">Modern</span>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        );
    }
}
