import * as React from "react";

import { Card, CardBody, CardHeader, CardTitle, Spinner, Table } from "reactstrap";
import { Lambda, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../../stores/AppSettingStore";
import { PaginationWrapper } from "../../common/PaginationWrapper";
import { RenderingStatus } from "../../../enumerations/Enumerations";
import { RootStore } from "../../../stores/RootStore";

interface PayoutHistoryCardProps {
    root?: RootStore;
}

@inject("root") @observer
export class PayoutHistoryCard extends React.Component<PayoutHistoryCardProps, {}> {
    @observable private cashActivityList: CashActivity[] = [];
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Waiting;
    private disposer: Lambda;
    @observable private currentPage: number = 0;
    private MAX_PAGE_SIZE = 10;
    
    public componentDidMount() {
        this.fetchCashActivityListForUser();
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh || this.props.root.cashFlowStore.willRefresh,
            () => {
                this.fetchCashActivityListForUser();
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    public render() {
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h5" className="mb-0">
                        Payout history
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }

    private renderTable() {
        const pageCount = Math.ceil(this.cashActivityList.length / this.MAX_PAGE_SIZE);
        switch (this.loadingStatus) {
            case RenderingStatus.Error:
                return (
                    <div className="justify-content-center align-self-center w-100 text-center">Failed to load. Please try again.</div>
                ); 
            case RenderingStatus.Waiting:
                return (
                    <div className="mb-2 mr-3 mt-2 text-center">
                        <Spinner size="sm" className="mr-2"/>
                    </div>                     
                );
            default:
                if (this.cashActivityList.length === 0) {
                    return (
                        <div className="mb-2 text-center">
                            No data available
                        </div>
                    ); 
                }
                return (
                        <div>
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Date posted</th>
                                        <th className="d-none d-xl-table-cell">Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.cashActivityList.slice(this.currentPage * this.MAX_PAGE_SIZE,
                                            (this.currentPage + 1) * this.MAX_PAGE_SIZE
                                          ).map(activity => {
                                            return (
                                                <tr key={activity.id}>
                                                    <td> {new Date(activity.lastTimeUpdated).toUTCString()}</td>
                                                    <td className="d-none d-xl-table-cell">{activity.description}</td>
                                                    <td>{`${activity.currency} ${activity.amount}`}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                            {
                                this.cashActivityList.length > 0 && pageCount > 1 && 
                                <PaginationWrapper pagesCount={pageCount} handlePageClick={this.handlePageClick} />
                            }
                        </div>
                );
        }
    }

    private handlePageClick = (currentPage: number) => {
        this.currentPage = currentPage;
    }

    private async fetchCashActivityListForUser() {
        this.loadingStatus = RenderingStatus.Waiting;
        try {
            const query: CashActivityQuery = {
                collection: "costs",
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                status: "COMPLETED",
                settlementTo: this.props.root.auth.userUid
            };
            this.cashActivityList = await this.props.root.cashFlowStore.getCashActivityList(query) || [];
            this.cashActivityList = this.cashActivityList.sort((a, b) => b.lastTimeUpdated - a.lastTimeUpdated);
            this.loadingStatus = RenderingStatus.Ready;
        } catch (e) {
            console.log(e);
            this.loadingStatus = RenderingStatus.Error;
        }
    }
}