import * as React from "react";

import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { FormSubmissionStatus } from "../common/FormSubmissionStatus";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import UsersApiService from "../../services/Users.service";

interface ContactInfoCardProps {
    root?: RootStore;
}

@inject("root") @observer
export class ContactInfoCard extends React.Component<ContactInfoCardProps, {}> {
    @observable private currentUser: User;
    @observable private loadingStatus: RenderingStatus;
    @observable private submissionMesage: string = "";
    private cancelator: ApiServiceCancelator;
    
    public componentDidMount() {
        this.currentUser = { ...this.props.root.myInfoStore.profile };
    }

    public render(){
        return (
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5">Contact information</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                    <Label>First name</Label>
                                    <Input value={this.currentUser ? this.currentUser.firstName : ""} type="text" name="firstName"
                                        onChange={this.onChangeFirstName}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                    <Label>Last name</Label>
                                    <Input value={this.currentUser ? this.currentUser.lastName : ""} type="text" name="lastName"
                                            onChange={this.onChangeLastName} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup className="md">
                                        <Label>Phone number</Label>
                                        <Input value={this.currentUser ? this.currentUser.phoneNumber : ""} type="text" name="phoneNumber"
                                                onChange={this.onChangePhoneNumber}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div>
                                <Spinner size="sm" className="mr-2" hidden={this.loadingStatus !== RenderingStatus.Waiting}/>
                                <Button disabled={!this.formIsValid} onClick={this.updateContact} color="primary">Update contact information</Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <FormSubmissionStatus status={this.loadingStatus} message={this.submissionMesage}/>
            </div>
        );
    }

    @computed
    private get formIsValid() {
        return !!this.currentUser && !!this.currentUser.firstName && !!this.currentUser.lastName && !!this.currentUser.phoneNumber;
    }

    private onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.currentUser) {
            this.currentUser.firstName = event.target.value;
        }
    }

    private onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.currentUser) {
            this.currentUser.lastName = event.target.value;
        }
    }

    private onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.currentUser) {
            this.currentUser.phoneNumber = event.target.value;
        }
    }

    private updateContact = async () => {
        if (this.currentUser) {
            if (this.cancelator) {
                this.cancelator.cancel();
            }
            this.cancelator = new ApiServiceCancelator();
            this.loadingStatus = RenderingStatus.Waiting;
            try {
                await UsersApiService.updateUser(this.currentUser.userId, this.currentUser, this.cancelator);
                this.loadingStatus = RenderingStatus.Ready;
                this.submissionMesage = "";
                this.props.root.toastStore.showNotif({
                    title: "",
                    message: "Contact info successfully updated.",
                    duration: ToastDuration.Normal,
                    toastType: ToastMessageType.Success
                });
            } catch (e) {
                console.log(e);
                this.loadingStatus = RenderingStatus.Error;
                this.submissionMesage = "Contact info update failed. Please try again."
            }
        }
    }
}