import * as React from "react";

import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export class FormSubmissionStatus extends React.Component< { message: String, status: RenderingStatus, onDismiss?: () => void }, {}> {
    
    public render() {
        return (
            <Alert key="formSubmission" color={this.props.status === RenderingStatus.Error ? "danger" : "success"} 
                hidden={!this.props.message}>
                <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} />
                </div>
                <div className="alert-message text-left">
                    {this.props.message}
                </div>
            </Alert>
        );
    }
}