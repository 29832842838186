import CashFlowService from "../services/CashFlow.service";
import { ObservableMap } from "mobx";
import { RefreshStore } from "./RefreshStore";
import { Utils } from "../utils/Utils";

export class CashFlowStore extends RefreshStore {
    private cashActivityListCache = new ObservableMap<string, CashActivity[]>({});
    private cashActivityCache = new ObservableMap<string, CashActivity>({});

    public async getCashActivityList(query: CashActivityQuery) {
        const key = Utils.getHash(query);
        const list = this.cashActivityListCache.get(key);
        if (!list) {
            await this.updateCashActivityListCache(query);
            return this.cashActivityListCache.get(key);
        }
        return list;
    }

    public async updateCashActivityListCache(query: CashActivityQuery) {
        const key = Utils.getHash(query);
        const apiResponse = await CashFlowService.getCashActivitiesGivenQuery(query);
        if (apiResponse.data.status === "OK") {
            this.cashActivityListCache.set(key, apiResponse.data.response.cashActivities);
            apiResponse.data.response.cashActivities.forEach(activity => {
                this.cashActivityCache.set(activity.id, activity);
            });
        }
    }

}