import { ApiService } from "../apiservices/ApiService";
import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import Endpoints from "./Endpoints";

export default class BeaconService {
    
    public static getBeacons = (queries: PublicBeaconInfoQuery[], cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: BeaconInfo[] }}> => {
        return ApiService.request({
            url: Endpoints.PUBLIC_BEACON_INFO_ENDPOINT,
            method: "post",
            data: queries
        }, cancelator);
    }

    public static getBeaconRegistrationInfos = (query: BeaconRegistrationInfoQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response:BeaconRegistrationInfo[] }}> => {
        var url = Endpoints.BEACON_REGISTRATION_INFO_ENDPOINT + `?beaconId=${query.beaconId || ""}&productIdentifier=${query.productIdentifier || ""}&country=${query.country}`;
        if (query.registrationStartDate) {
            url += `&registrationStartDate=${query.registrationStartDate}`
        }
        if (query.registrationEndDate) {
            url += `&registrationEndDate=${query.registrationEndDate}`
        }
        if (query.commissionStartDate) {
            url += `&commissionStartDate=${query.commissionStartDate}`
        }
        if (query.commissionEndDate) {
            url += `&commissionEndDate=${query.commissionEndDate}`
        }
        if (query.lastUpdatedStartDate) {
            url += `&lastUpdatedStartDate=${query.lastUpdatedStartDate}`
        }
        if (query.lastUpdatedEndDate) {
            url += `&lastUpdatedEndDate=${query.lastUpdatedEndDate}`
        }

        return ApiService.request({
            url: url,
            method: "get",
            data: query
        }, cancelator);
    }

    public static registerBeacon = (registrationInfo: BeaconRegistrationInfo, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response:Message }}> => {
        return ApiService.request({
            url: Endpoints.BEACON_REGISTRATION_INFO_ENDPOINT,
            method: "post",
            data: registrationInfo
        }, cancelator);
    }

    public static updateBeaconRegistrationInfo = (beaconId: string, update: BeaconRegistrationInfo, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response:Message }}> => {
        return ApiService.request({
            url: Endpoints.BEACON_REGISTRATION_INFO_ENDPOINT + `?beaconId=${beaconId}`,
            method: "patch",
            data: update
        }, cancelator);
    }

    public static generateBeaconUID = (cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: string }}> => {
        return ApiService.request({
            url: Endpoints.GENERATE_NEW_BEACON_UID,
            method: "get",
            data: null
        }, cancelator);
    }

    public static commissionBeacon = (request: BeaconCommissionRequest, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: Message }}> => {
        return ApiService.request({
            url: Endpoints.COMMISSION_BEACON_ENDPOINT,
            method: "post",
            data: request
        }, cancelator);
    }

    public static getCommissionRequests = (query: BeaconCommissionRequestQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: BeaconCommissionRequest[] }}> => {
        var url = Endpoints.COMMISSION_BEACON_ENDPOINT + `?submittedBy=${query.submittedBy || ""}&beaconId=${query.beaconId || ""}&country=${query.country || ""}&phoneNumber=${query.phoneNumber || ""}`;
        if (query.lines) {
            url += `&lines=${encodeURIComponent(query.lines.join(","))}`;
        }
        if (query.startTime) {
            url += `&startTime=${query.startTime}`;
        }
        if (query.endTime) {
            url += `&endTime=${query.endTime}`;
        }
        return ApiService.request({
            url: url,
            method: "get",
            data: query
        }, cancelator);
    }

    public static getBeaconEngagementData = (beaconId: string, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: BeaconEngagement }}> => {
        return ApiService.request({
            url: Endpoints.BEACON_ENGAGEMENT_ENDPOINT + `?beaconId=${beaconId || ""}`,
            method: "get",
            data: null
        }, cancelator);
    }

}