import { ApiService } from "../apiservices/ApiService";
import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import Endpoints from "./Endpoints";

export default class UsersApiService {
    public static getUsers = (query: UserQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: User[]}}> => {
        let queryString = "";
        if (query.userId || query.phoneNumber) {
            queryString = query.userId ? `?userId=${encodeURIComponent(query.userId)}` : `?phoneNumber=${encodeURIComponent(query.phoneNumber)}`;
        }
        if (query.userIds) {
            queryString += `${queryString ? "&" : "?"}userIds=${encodeURIComponent(query.userIds.join(","))}`;
        }
        return ApiService.request({
            url: Endpoints.USERS_ENDPOINT + queryString,
            method: "get",
            data: null
        }, cancelator);
    }

    public static createUser = (request: CreateUserRequest, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: User}}> => {
        return ApiService.request({
            url: Endpoints.USERS_ENDPOINT,
            method: "post",
            data: request
        }, cancelator);
    }

    public static updateUser = (userId: string, update: User, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: any}}> => {
        let url = Endpoints.USERS_ENDPOINT + `?userId=${userId || ""}`;
        return ApiService.request({
            url: url,
            method: "patch",
            data: update
        }, cancelator);
    }

}