import * as React from "react";

import { Col, Container, Row } from "reactstrap";
import { SettingsNavigation, SettingsNavigationOption } from "../../components/settings/SettingsNavigation";
import { inject, observer } from "mobx-react";

import { ChangePasswordCard } from "../../components/settings/ChangePasswordCard";
import { ContactInfoCard } from "../../components/settings/ContactInfoCard";
import { RootStore } from "../../stores/RootStore";
import { observable } from "mobx";

interface UserSettingsProps {
    root?: RootStore;
}

@inject("root") @observer
export class UserSettings extends React.Component<UserSettingsProps, {}> {
    private options: SettingsNavigationOption[];
    @observable private selectedKey: string;
    
    constructor(props: UserSettingsProps) {
        super(props);
        this.options = [
            {
                key: "info",
                name: "Profile information"
            },
            {
                key: "password",
                name: "Password"
            }
        ]
    }

    public render() {
        return (
            <Container fluid className="p-0">
                {/* <h1 className="h3 mb-3">Settings</h1> */}
                <Row>
                <Col md="3" xl="2">
                    <SettingsNavigation options={this.options} onClickNavigationOption={this.onClickNavigationOption} />
                </Col>
                <Col md="9" xl="10">
                    {this.renderContent(this.selectedKey)}
                </Col>
                </Row>
                {/* <Row className="justify-content-center">
                    <Col lg="10">
                        <ContactInfoCard />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="10">
                        <ChangePasswordCard />
                    </Col>
                </Row> */}
                
            </Container>
        );
    }

    private renderContent(key: string) {
        switch (key) {
            case "password":
                return <ChangePasswordCard/>
            default:
                return <ContactInfoCard/>
        }
    }

    private onClickNavigationOption = (key: string) => {
        this.selectedKey = key;
    }
}