import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import { ObservableMap } from "mobx";
import UsersApiService from "../services/Users.service";
import { Utils } from "../utils/Utils";

export class UsersStore {
    private userQueriesCache = new ObservableMap<string, User[]>({});
    public userIdMap = new ObservableMap<string, User>({});
    public phoneNumberMap = new ObservableMap<string, User>({});

    public async getUsers(query: UserQuery) {
        const key = Utils.getHash(query);
        const users = this.userQueriesCache.get(key);
        if (!users) {
            await this.fetchUsers(query);
            return this.userQueriesCache.get(key);
        }
        return users;
    }

    // public async getUserInfo(userId?: string, phoneNumber?: string) {
    //     const user = this.userIdMap.get(userId);
    //     if (!user) {
    //         await this.fetchUsers({
    //             userId: userId,
    //             phoneNumber: phoneNumber
    //         });
    //         return this.userIdMap.get(userId);
    //     }
    //     return user;
    // }

    public async fetchUsers(query: UserQuery, cancelator?: ApiServiceCancelator) {
        const key = Utils.getHash(query);
        const result = this.userQueriesCache.get(key);
        if (!result) {
            try {
                const apiResponse = await UsersApiService.getUsers(query, cancelator);
                this.userQueriesCache.set(key, apiResponse.data.response);
                if (apiResponse.data.response) {
                    apiResponse.data.response.forEach(user => {
                        this.userIdMap.set(user.userId, user);
                        this.phoneNumberMap.set(user.phoneNumber, user);
                    });
                }
                return apiResponse.data.response || [];
            } catch (err) {
                console.log(err);
            }
        }   
        return result;
    }
}