import { inject, observer } from "mobx-react";

import { CommissionBeacon } from "../../components/beacons/CommissionBeacon";
import { Container } from "reactstrap";
import { OnboardedUsersTable } from "../../components/beacons/OnboardedUsersTable";
import React from "react";
import { RootStore } from "../../stores/RootStore";
import { observable } from "mobx";

@inject("root") @observer
export class OnboardDriver extends React.Component<{ root?: RootStore }, {}> {
    @observable private body: JSX.Element;

    constructor(props) {
        super(props);
        this.body = <CommissionBeacon dismissCallback={this.resetCommissionFormWizard} />
    }

    public render() {
        return (
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Onboard new user</h1>
                {this.body}
                <OnboardedUsersTable />
            </Container>
            
        );
    }

    private resetCommissionFormWizard = () => {
        this.body = <CommissionBeacon dismissCallback={this.resetCommissionFormWizard} />
    }
}