import * as React from "react";

import { Card, CardBody, Media, Spinner } from "reactstrap";
import { Lambda, computed, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../../stores/AppSettingStore";
import { RenderingStatus } from "../../../enumerations/Enumerations";
import { RootStore } from "../../../stores/RootStore";
import { Users } from "react-feather";

interface OnboardedUserCountCardProps {
    root?: RootStore;
}

@inject("root") @observer
export class OnboardedUserCountCard extends React.Component<OnboardedUserCountCardProps, {}> {
    private commissionRequests: BeaconCommissionRequest[] = [];
    @observable private renderingStatus: RenderingStatus = RenderingStatus.Waiting;
    private disposer: Lambda;

    public async componentDidMount() {
        this.fetchUserCount();
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh,
            () => {
                this.fetchUserCount();
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    public render() {
        return (
            <Card className="flex-fill">
                <CardBody className="py-4">
                    <Media>
                        <div className="d-inline-block mt-2 mr-3">
                            <Users className="feather-lg text-primary" />
                        </div>
                        {this.renderBody()}
                    </Media>
                </CardBody>
            </Card>
        );
    }

    @computed
    private get userCount() {
        const userSet = new Set<string>();
        this.commissionRequests.forEach(request => {
            userSet.add(request.userId);
        });
        return userSet.size;
    }

    private async fetchUserCount() {
        const beaconStore = this.props.root.beaconStore;
        this.renderingStatus = RenderingStatus.Waiting;            
        try {
            const query: BeaconCommissionRequestQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                submittedBy: this.props.root.auth.userUid
            };
            this.commissionRequests = await beaconStore.getCommissionBeaconRequestList(query) || [];
            this.renderingStatus = RenderingStatus.Ready;
        } catch (err) {
            console.log(err);
            this.renderingStatus = RenderingStatus.Error;
        }
    }

    private renderBody() {
        switch (this.renderingStatus) {
            case RenderingStatus.Error:
                return (
                    <Media body>
                        <div className="mb-2">Failed to load. Please try again.</div>
                    </Media>
                ) 
            case RenderingStatus.Waiting:
                return (
                    <Media body>
                        <div className="mb-2 mr-3 mt-2 text-center">
                            <Spinner size="sm" className="mr-2"/>
                        </div> 
                    </Media>
                );
            default:
                return (
                    <Media body>
                        <h3 className="mb-2">{this.userCount}</h3>
                        <div className="mb-0">Drivers you've onboarded</div>
                    </Media>
                );
        }
    }
}