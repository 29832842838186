import * as ObjectHash from "object-hash"

import { toJS } from "mobx";

export class Utils {
    public static getHash(obj: any) {
        return ObjectHash(toJS(obj));
    }

    public static startOfToday() {
        var startOfDay = new Date();
        startOfDay.setHours(0,0,0,0);
        return startOfDay;
    }

    public static endOfToday() {
        var endOfDay = new Date();
        endOfDay.setHours(23,59,59,999);
        return endOfDay;
    }

    public static convertBase64ToHexString(str: string) {
        return new Buffer(str, 'base64').toString('hex');
    }

    public static convertBase64ToUtf8String(str: string) {
        return atob(str);
    }
}