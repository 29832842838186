import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Spinner,
} from "reactstrap";
import { Lambda, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { AppSettingStore } from "../../stores/AppSettingStore";
import { Lane } from "../../components/tasks/Lane";
import { ProcessInProgressTaskForm } from "../../components/tasks/ProcessInProgressTaskForm";
import { ProcessPendingTaskView } from "../../components/tasks/ProcessPendingTaskView";
import React from "react";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { TaskDetailView } from "../../components/tasks/TaskDetailView";
import { TaskItem } from "../../components/tasks/TaskItem";

interface TasksProps {
    root?: RootStore;
}

@inject("root") @observer
export class Tasks extends React.Component<TasksProps, {}> {
    @observable private completedTasksLoadingStatus: RenderingStatus = RenderingStatus.Waiting;
    @observable private inprogressTasksLoadingStatus: RenderingStatus = RenderingStatus.Waiting;
    @observable private tasksAssignedToMeLoadingStatus: RenderingStatus = RenderingStatus.Waiting;
    @observable private completedTasks: Task[] = [];
    @observable private inprogressTasks: Task[] = [];
    @observable private tasksAssignedToMe: Task[] = [];
    @observable private modalContent: JSX.Element;
    @observable private modalVisibilityStatus = false;
    private disposer: Lambda;

    private toggleModalVisibility = () => {
        this.modalVisibilityStatus = !this.modalVisibilityStatus;
    }

    public componentDidMount() {
        this.fetchCompletedTasks();
        this.fetchTasksInProgress();
        this.fetchTasksAssignedToMe();
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh,
            () => {
                this.fetchCompletedTasks();
                this.fetchTasksInProgress();
                this.fetchTasksAssignedToMe();
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    private renderModalPopup() {
        return (
            <Modal
                isOpen={this.modalVisibilityStatus}
                toggle={this.toggleModalVisibility}
                size={"md"}
            >
                <ModalBody>
                    {this.modalContent}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggleModalVisibility}>
                        Close
                  </Button>{" "}
                </ModalFooter>
            </Modal>
        );
    }


    public render() {
        return (
            <Container fluid className="p-0">
                {this.renderModalPopup()}
                <h1 className="h3 mb-3">Tasks assigned to me</h1>
                <Row>
                    <Col lg="6" xl="3">
                        <Lane name={`In Progress(${this.inprogressTasks.length})`} onRefresh={this.updateTasksInProgress} refreshFailMessage={"Failed to refresh tasks in progress"}>
                            {
                                this.renderLaneBody(this.inprogressTasks, this.inprogressTasksLoadingStatus, this.showProcessInProgressForm)
                            }
                        </Lane>
                    </Col>
                    <Col lg="6" xl="3">
                        <Lane name={`Pending(${this.tasksAssignedToMe.length})`} onRefresh={this.updateTasksAssignedToMe} refreshFailMessage={"Failed to refresh tasks assigned to you"}>
                            {
                                this.renderLaneBody(this.tasksAssignedToMe, this.tasksAssignedToMeLoadingStatus, this.showProcessPendingTaskView)
                            }
                        </Lane>
                    </Col>
                    <Col lg="6" xl="3">
                        <Lane name={`Completed(${this.completedTasks.length})`} onRefresh={this.updateCompletedTasks} refreshFailMessage={"Failed to refresh completed tasks list"}>
                            {
                                this.renderLaneBody(this.completedTasks, this.completedTasksLoadingStatus, this.showCompletedTaskDetailView)
                            }
                        </Lane>
                    </Col>
                </Row>
            </Container>
        );
    }

    private renderLaneBody(collection: Task[], renderingStatus: RenderingStatus, selectionHandler?: (task: Task) => () => void) {
        switch (renderingStatus) {
            case RenderingStatus.Error:
                return (
                        <div className="mb-2 text-center">Failed to load. Please try again.</div>
                    ); 
            case RenderingStatus.Waiting:
                return (
                    <div className="mb-2 mr-3 mt-2 text-center">
                        <Spinner size="sm" className="mr-2"/>
                    </div> 
                    
                );
            default:
                if (collection.length === 0) {
                    return (
                        <div className="mb-2 text-center">No tasks</div> 
                    );
                }
                return collection.map(task => {
                    return (
                        <TaskItem
                            key={task.id}
                            task={task}
                            onClick={selectionHandler ? selectionHandler(task) : () => {}}
                        />
                    );
                });
        }
    }
    
    private async fetchCompletedTasks() {
        const taskStore = this.props.root.taskStore;
        this.completedTasksLoadingStatus = RenderingStatus.Waiting
        try {
            const query: TaskQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                assignedTo: this.props.root.auth.userUid,
                status: "COMPLETED",
                type: "onboard"
            };
            this.completedTasks = await taskStore.getTasksList(query) || [];
            this.completedTasks = this.completedTasks.sort((a, b) => b.lastTimeUpdated - a.lastTimeUpdated);
            this.completedTasksLoadingStatus = RenderingStatus.Ready
        } catch (err) {
            console.log(err);
            this.completedTasksLoadingStatus = RenderingStatus.Error;
        }  
    }

    private updateCompletedTasks = async (cancelator?: ApiServiceCancelator) => {
        const taskStore = this.props.root.taskStore;
        const query: TaskQuery = {
            country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
            assignedTo: this.props.root.auth.userUid,
            status: "COMPLETED",
            type: "onboard"
        };
        await taskStore.updateTasksListCache(query, cancelator);
        this.fetchCompletedTasks();
    }

    private async fetchTasksAssignedToMe() {
        const taskStore = this.props.root.taskStore;
        this.tasksAssignedToMeLoadingStatus = RenderingStatus.Waiting
        try {
            const query: TaskQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                status: "PENDING",
                assignedTo: this.props.root.auth.userUid,
                type: "onboard"
            };
            this.tasksAssignedToMe = await taskStore.getTasksList(query) || [];
            this.tasksAssignedToMe = this.tasksAssignedToMe.sort((a, b) => b.lastTimeUpdated - a.lastTimeUpdated);
            this.tasksAssignedToMeLoadingStatus = RenderingStatus.Ready
        } catch (err) {
            console.log(err);
            this.tasksAssignedToMeLoadingStatus = RenderingStatus.Error;
        }  
    }

    private updateTasksAssignedToMe = async (cancelator?: ApiServiceCancelator) => {
        const taskStore = this.props.root.taskStore;
        const query: TaskQuery = {
            country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
            assignedTo: this.props.root.auth.userUid,
            status: "PENDING",
            type: "onboard"
        };
        await taskStore.updateTasksListCache(query, cancelator);
        this.fetchTasksAssignedToMe();
    }

    private async fetchTasksInProgress() {
        const taskStore = this.props.root.taskStore;
        this.inprogressTasksLoadingStatus = RenderingStatus.Waiting
        try {
            const query: TaskQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                assignedTo: this.props.root.auth.userUid,
                status: "INPROGRESS",
                type: "onboard"
            };
            this.inprogressTasks = await taskStore.getTasksList(query) || [];
            this.inprogressTasks = this.inprogressTasks.sort((a, b) => b.lastTimeUpdated - a.lastTimeUpdated);
            this.inprogressTasksLoadingStatus = RenderingStatus.Ready
        } catch (err) {
            console.log(err);
            this.inprogressTasksLoadingStatus = RenderingStatus.Error;
        }  
    }

    private updateTasksInProgress= async (cancelator?: ApiServiceCancelator) => {
        const taskStore = this.props.root.taskStore;
        const query: TaskQuery = {
            country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
            assignedTo: this.props.root.auth.userUid,
            status: "INPROGRESS",
            type: "onboard"
        };
        await taskStore.updateTasksListCache(query, cancelator);
        this.fetchTasksInProgress();
    }

    private showProcessPendingTaskView = (task: Task) => () => {
        this.modalContent = <ProcessPendingTaskView task={task} dismissCallback={this.processPendingTaskViewDismissHandler} />
        this.toggleModalVisibility();        
    }

    private showProcessInProgressForm = (task: Task) => () => {
        this.modalContent = <ProcessInProgressTaskForm task={task} dismissCallback={this.processInProgressTaskFormDismissHandler} />
        this.toggleModalVisibility(); 
    }

    private showCompletedTaskDetailView = (task: Task) => () => {
        this.modalContent = <TaskDetailView task={task} />
        this.toggleModalVisibility(); 
    }

    private processPendingTaskViewDismissHandler = async () => {
        this.toggleModalVisibility();
        await this.updateTasksInProgress();
        await this.updateTasksAssignedToMe();
    }

    private processInProgressTaskFormDismissHandler = async () => {
        this.toggleModalVisibility();
        await this.updateTasksInProgress();
        await this.updateCompletedTasks();
        await this.updateTasksAssignedToMe();
        this.props.root.taskStore.triggerRefresh();
        this.props.root.cashFlowStore.triggerRefresh();
    }
    
}