import * as React from "react";

import { FormWizard, FormWizardItem } from "../common/FormWizard";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { inject, observer } from "mobx-react";

import { ApiServiceCancelator } from "../../apiservices/ApiServiceCancelator";
import { AppSettingStore } from "../../stores/AppSettingStore";
import { BeaconLookupAndLineSelectionForm } from "./BeaconLookupAndLineSelectionForm";
import BeaconService from "../../services/Beacon.service";
import { BeaconUserLookup } from "./BeaconUserLookup";
import { FormSubmissionStatus } from "../common/FormSubmissionStatus";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import TaskService from "../../services/Task.service";
import { observable } from "mobx";
import { parse } from "query-string";

interface CommissionBeaconProps extends RouteComponentProps {
    root?: RootStore;
    dismissCallback?: (message?: string) => void;
}

@inject("root") @observer
class CommissionBeacon extends React.Component<CommissionBeaconProps, {}> {
    private cancelator: ApiServiceCancelator;
    @observable private formItems: FormWizardItem[] = [];
    @observable private submissionStatus: SubmissionStatus;
    @observable private commissionRequest: BeaconCommissionRequest;
    private taskId: string = "";

    constructor(props: CommissionBeaconProps) {
        super(props);
        const { phoneNumber, taskId } = parse(this.props.location.search);
        this.taskId = taskId;
        this.submissionStatus = {
            message: "",
            loadingStatus: RenderingStatus.Waiting
        };
        this.commissionRequest = {
            userId: "",
            phoneNumber: phoneNumber || "",
            beaconId: "",
            lines: []
        };
        this.formItems = [
            {
                title: "Step 1",
                key: "step-1",
                description: "",
                body: <BeaconUserLookup commissionRequest={this.commissionRequest} dismissHandler={this.markTaskAsCompletedAndResetForm}
                        submissionStatus={this.submissionStatus}/> 
            },
            {
                title: "Step 2",
                key: "step-2",
                description: "",
                body: <BeaconLookupAndLineSelectionForm commissionRequest={this.commissionRequest} submissionStatus={this.submissionStatus}/>
            }
        ];
    }

    private get formIsValid() {
        return !!this.commissionRequest.userId && !!this.commissionRequest.beaconId && this.commissionRequest.lines.length > 0;
    }
    
    public render() {
        return (
            <div>
                <FormWizard variant="default" color="secondary" formItems={this.formItems}
                    stepsValidationCallback={this.validateSteps} onCompleteFormCallback={this.submit}/>
                <FormSubmissionStatus message={this.submissionStatus.message} status={this.submissionStatus.loadingStatus} />
            </div>
        );
    }

    private validateSteps = (e: any, anchorObject: any, stepNumber: number, stepDirection: string) => {
        if (stepNumber === 0 && stepDirection === "forward") {
            return !!this.commissionRequest.userId;
        }
        return true;
    }

    private reset() {
        this.props.history.push(this.props.location.pathname);
        this.commissionRequest = {
            userId: "",
            phoneNumber: "",
            beaconId: "",
            lines: []
        };
        this.formItems = [
            {
                title: "Step 1",
                key: "step-1",
                description: "",
                body: <BeaconUserLookup commissionRequest={this.commissionRequest} dismissHandler={this.markTaskAsCompletedAndResetForm}
                        submissionStatus={this.submissionStatus}/> 
            },
            {
                title: "Step 2",
                key: "step-2",
                description: "",
                body: <BeaconLookupAndLineSelectionForm commissionRequest={this.commissionRequest} submissionStatus={this.submissionStatus}/>
            }
        ];
    }

    private  markTaskAsCompletedAndResetForm = async () => {
        if (this.taskId) {
            await TaskService.updateTask( {
                taskId: this.taskId,
                status: "COMPLETED"
            }, this.cancelator);
            this.updateTaskInProgressAndCompletedTasks();
        }
        this.reset();
    }

    private submit = async () => {
        try {
            if (this.cancelator) {
                this.cancelator.cancel();
            }
            this.cancelator = new ApiServiceCancelator();
            this.submissionStatus.loadingStatus = RenderingStatus.Waiting;
            this.submissionStatus.message = "";
            if (this.formIsValid) {
                await BeaconService.commissionBeacon(this.commissionRequest, this.cancelator);
                await this.props.root.beaconStore.updateBeaconRegistrationInfoListCache({});
                this.submissionStatus.loadingStatus = RenderingStatus.Ready;
                this.props.root.toastStore.showNotif({
                    title: "Success",
                    message: "Beacon successfully commissioned.",
                    duration: ToastDuration.Normal,
                    toastType: ToastMessageType.Success
                });
                if (this.taskId) {
                    await TaskService.updateTask( {
                        taskId: this.taskId,
                        status: "COMPLETED"
                    }, this.cancelator);
                    this.updateTaskInProgressAndCompletedTasks();
                }
                if (this.props.dismissCallback) {
                    this.props.dismissCallback(this.submissionStatus.message);
                }
                this.reset();
                const query: BeaconCommissionRequestQuery = {
                    country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                    submittedBy: this.props.root.auth.userUid
                };
                this.props.root.beaconStore.updateCommissionBeaconRequestList(query);
                this.props.root.beaconStore.triggerRefresh();
                return true;
            } else {
                this.submissionStatus.loadingStatus = RenderingStatus.Error;
                this.submissionStatus.message = "Incomplete. Please fill out all fields";
            }
        } catch (err) {
            console.log(err);
            this.submissionStatus.loadingStatus = RenderingStatus.Error;
            this.submissionStatus.message = "" + err;
        }
        return false;
    }

    private async updateTaskInProgressAndCompletedTasks() {
        const taskStore = this.props.root.taskStore;
        const query: TaskQuery = {
            country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
            assignedTo: this.props.root.auth.userUid,
            status: "COMPLETED"
        };
        await taskStore.updateTasksListCache(query);
        query.status = "INPROGRESS";
        await taskStore.updateTasksListCache(query);
        this.props.root.taskStore.triggerRefresh();
    }
}


const wrapper = withRouter(CommissionBeacon);
export { wrapper as CommissionBeacon };