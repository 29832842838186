import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { ApiServiceCancelator } from "./ApiServiceCancelator";
import { ApiServiceRequestScheduler } from "./ApiServiceRequestScheduler";
import { Root } from "../stores/RootStore";

class ApiService {
    private totalMaxPendingPromisesCount = 7;
    private totalMaxNormalPendingPromisesCount = 7;
    private totalMaxSlowPendingPromisesCount = 2;

    private scheduler: ApiServiceRequestScheduler = new ApiServiceRequestScheduler(
        this.totalMaxPendingPromisesCount, this.totalMaxNormalPendingPromisesCount, this.totalMaxSlowPendingPromisesCount);
    private axiosInstance: AxiosInstance = null;

    public request(param: AxiosRequestConfig, cancelator?: ApiServiceCancelator) {
        param.timeout = 15000;
        return this.scheduler.add(this.axiosInstance, param, cancelator);
    }

    public retry(param: AxiosRequestConfig) {
        return this.scheduler.add(this.axiosInstance, param, undefined);
    }

    public cancel(serviceId: string) {
        this.scheduler.cancel(serviceId);
    }

    public initialize() {
        const baseUrl = Root.setting.BackendServiceApiUrl;
        
        this.axiosInstance = axios.create({
            baseURL: baseUrl
        });

        this.axiosInstance.interceptors.request.use(async (config) => {
            await this.configAuth(config);
            return config;
        });
    }

    private async configAuth(config: AxiosRequestConfig) {
        const token = await Root.auth.getAuthToken();
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
    }
}

const instance = new ApiService();
export { instance as ApiService };