import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import { ObservableMap } from "mobx";
import { RefreshStore } from "./RefreshStore";
import { RootStore } from "./RootStore";
import TaskService from "../services/Task.service";
import { Utils } from "../utils/Utils";

export class TaskStore extends RefreshStore {
    private root: RootStore;
    private tasksListCache = new ObservableMap<string, Task[]>({});
    private tasksCache = new ObservableMap<string, Task>({});

    constructor(root: RootStore) {
        super();
        this.root = root;
    }

    public async getTasksList(query: TaskQuery) {
        const key = Utils.getHash(query);
        const list = this.tasksListCache.get(key);
        if (!list) {
            await this.updateTasksListCache(query);
            return this.tasksListCache.get(key);
        }
        return list;
    }

    public async getTask(id: string) {
        if (this.tasksCache.has(id)) {
            return this.tasksCache.get(id);
        }
        let result: Task;
        const apiResponse = await TaskService.getTasksGivenQuery({id: id});
        if (apiResponse.data.status === "OK" && apiResponse.data.response.tasks.length > 0) {
            result = apiResponse.data.response.tasks[0];
            this.tasksCache.set(result.id, result);
        }
        return result;
    }
    
    public async updateTasksListCache(query: TaskQuery, cancelator?: ApiServiceCancelator) {
        const key = Utils.getHash(query);
        const apiResponse = await TaskService.getTasksGivenQuery(query, cancelator);
        if (apiResponse.data.status === "OK") {
            this.tasksListCache.set(key, apiResponse.data.response.tasks);
            apiResponse.data.response.tasks.forEach(task => {
                this.tasksCache.set(task.id, task);
            });
        }
    }
}