import { RouteComponentProps, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Activity } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import { RootStore } from "../../stores/RootStore";
import { SidebarCategory } from "./SidebarCategory"
import { SidebarItem } from "./SidebarItem"
import { CoreRoutes as routes } from "../../stores/RouteConfig";

interface SidebarProps extends RouteComponentProps {
    root?: RootStore;
}

@inject("root") @observer
class Sidebar extends React.Component<SidebarProps, {}> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    toggle = index => {
        // Collapse all elements
        Object.keys(this.state).forEach(
            item =>
                this.state[index] ||
                this.setState(() => ({
                    [item]: false
                }))
        );

        // Toggle selected element
        this.setState(state => ({
            [index]: !state[index]
        }));
    };

    public componentWillMount() {
        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            // const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" ? true : false;

            this.setState(() => ({
                [index]: isActive || isHome
            }));
        });
    }

    private buildStandaloneMenuOrDropdownMenu(item: RouteCategory, index: any) {
        if (item.standalone) {
            return this.buildStandaloneMenuItem(item);
        } else {
            return this.buildDropDownMenu(item, index);
        }
    }

    private buildDropDownMenu(item: RouteCategory, index: any) {
        return (
            <SidebarCategory
                name={item.title}
                badgeColor={item.badgeColor}
                badgeText={item.badgeText}
                icon={item.icon}
                to={item.path}
                isOpen={this.state[index]}
                onClick={() => this.toggle(index)}
            >
                {item.children.map((route, index) => (
                    <SidebarItem
                        key={index}
                        title={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                    />
                ))}
            </SidebarCategory>
        );
    }

    private buildStandaloneMenuItem(item: RouteCategory) {
        return (
            <SidebarItem
                title={item.title}
                to={item.path}
                icon={item.icon}
                badgeColor={item.badgeColor}
                badgeText={item.badgeText}
            />
        );
    }
    public render() {

        return (
            <nav
                className={
                    "sidebar" +
                    (!this.props.root.isSidebarOpened ? " toggled" : "") +
                    (this.props.root.isSidebarSticky ? " sidebar-sticky" : "")
                }
            >
                <div className="sidebar-content">
                    <PerfectScrollbar>
                        <a className="sidebar-brand" href="#">
                            <Activity className="align-middle text-primary" size={24} />{" "}
                            {/* <PieChart className="align-middle text-primary" size={24} />{" "} */}
                            <span className="align-middle">BuzzTop Agent</span>
                        </a>

                        <ul className="sidebar-nav">
                            {routes.map((category, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {category.header ? (
                                            <li className="sidebar-header">{category.header}</li>
                                        ) : null}
                                        {
                                            this.buildStandaloneMenuOrDropdownMenu(category, index)
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        );
    }
}

const wrapper = withRouter(Sidebar);

export { wrapper as Sidebar }