import * as React from "react";

import Content from "./Content";
import { Footer } from "../../components/nav/Footer";
import { LayoutSettings } from "../nav/LayoutSettings";
import Main from "./Main";
import { Navbar } from "../nav/Navbar";
import { Sidebar } from "../../components/nav/Sidebar";
import Wrapper from "./Wrapper";

export class SiteLayout extends React.Component<{}, {}> {
    public render() {
        return (
            <React.Fragment>
                <Wrapper>
                    <Sidebar />
                    <Main className= "flex-fill">
                        <Navbar />
                        <Content>{this.props.children}</Content>
                        {/* <Footer /> */}
                    </Main>
                </Wrapper>
                <LayoutSettings />
            </React.Fragment>
        )
    }
}
