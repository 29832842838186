import { computed, observable } from "mobx";

export class RefreshStore {
    @observable private refreshSwitch = 0;

    @computed public get willRefresh() {
        return this.refreshSwitch;
    }

    public triggerRefresh() {
        this.refreshSwitch++;
    }
}