import {
    AlertCircle,
    Bell,
    BellOff,
    Home,
    Settings,
    UserPlus
} from "react-feather";
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    UncontrolledDropdown
} from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../stores/AppSettingStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavbarDropdown } from "./NavbarDropdown";
import { NavbarDropdownItem } from "./NavbarDropdownItem";
import React from "react";
import { RootStore } from "../../stores/RootStore";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import { observable } from "mobx";

interface NavbarComponentProps extends RouteComponentProps {
    root?: RootStore;
}

@inject("root") @observer
class NavbarComponent extends React.Component<NavbarComponentProps, {}> {

    @observable private selectedCountry: CountryInfo;
    private supportedCountries: CountryInfo[] = [];

    constructor(props: NavbarComponentProps) {
        super(props);
        this.selectedCountry = this.props.root.settingStore.supportedCountriesMap.get(this.props.root.settingStore.selectedCountry);
        this.props.root.settingStore.supportedCountriesMap.forEach((value) => {
            this.supportedCountries.push(value);
        });
    }
    
    public render() {
        const profile = this.props.root.myInfoStore.profile
        const adminName = profile ? `${profile.firstName || ""} ${profile.lastName || ""}` : "Admin"
        return (
            <Navbar color="white" light expand>
                <span
                    className="sidebar-toggle d-flex mr-2"
                    onClick={() => {
                        this.props.root.toggleSidebar();
                    }}
                >
                    <i className="hamburger align-self-center" />
                </span>
                <Collapse navbar>
                    <Nav className="ml-auto" navbar>    
                        <UncontrolledDropdown nav inNavbar className="mr-2">
                            <DropdownToggle nav caret className="nav-flag">
                                {
                                    this.selectedCountry.flagImg ? <img src={this.selectedCountry.flagImg} alt={this.selectedCountry.name} /> :
                                    <FontAwesomeIcon icon={faStarOfLife} />
                                }
                                
                            </DropdownToggle>
                            <DropdownMenu right>
                                {
                                    this.supportedCountries.map((country) => {
                                        return (
                                            <DropdownItem key={country.iso} onClick={this.onSelectCountry(country.iso)}>
                                                {
                                                    country.flagImg &&
                                                    <img
                                                        src={country.flagImg}
                                                        alt={country.name}
                                                        width="20"
                                                        className="align-middle mr-1"
                                                    />
                                                }
                                                <span className="align-middle">{country.name}</span>
                                            </DropdownItem>
                                        )
                                    })
                                }

                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <span className="d-inline-block d-sm-none">
                                <DropdownToggle nav caret>
                                    <Settings size={18} className="align-middle" />
                                </DropdownToggle>
                            </span>
                            <span className="d-none d-sm-inline-block">
                                <DropdownToggle nav caret>
                                    <span className="text-dark">{adminName}</span>
                                </DropdownToggle>
                            </span>
                            <DropdownMenu right>
                                <DropdownItem onClick={this.onClickSettings}>Settings</DropdownItem>
                                <DropdownItem onClick={this.signOut}>Sign out</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }

    private onClickSettings = () => {
        this.props.history.push("/settings");
    }

    private onSelectCountry = (iso: string) => () => {
        this.selectedCountry = this.props.root.settingStore.supportedCountriesMap.get(iso);
        localStorage.setItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY, iso);
        this.props.root.settingStore.triggerRefresh();
    }

    private readonly signOut = () => {this.props.root.auth.logout(); this.props.history.push("/login")}
}
const wrapper = withRouter(NavbarComponent)
export { wrapper as Navbar };

