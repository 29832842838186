import { ApiService } from "./ApiService";
import { newGuid } from "./GuidGenerator";

export class ApiServiceCancelator {
    private id: string;
    constructor() {
        this.id = newGuid();
    }

    public get Id() {
        return this.id;
    }

    public cancel() {
        ApiService.cancel(this.id);
    }
}