import { ApiService } from "../apiservices/ApiService";
import { ApiServiceCancelator } from "../apiservices/ApiServiceCancelator";
import Endpoints from "./Endpoints";

export default class CashFlowService {
    public static getCashActivitiesGivenQuery = (query: CashActivityQuery, cancelator?: ApiServiceCancelator): Promise<{data: {status: string, response: CashActivityListResponse }}> => {
        var url = Endpoints.CASH_ACTIVITY_ENDPOINT + `?status=${query.status || ""}&collection=${query.collection}&id=${query.id || ""}&cashActivityType=${query.cashActivityType || ""}&authorizedBy=${query.authorizedBy || ""}&submittedBy=${query.submittedBy || ""}&settlementTo=${query.settlementTo || ""}&country=${query.country || ""}`;
        if (query.createdStartTime) {
            url += `&createdStartTime=${query.createdStartTime}`;
        }
        if(query.createdEndTime) {
            url += `&createdEndTime=${query.createdEndTime}`
        }
        if (query.lastUpdatedStartTime) {
            url += `&lastUpdatedStartTime=${query.lastUpdatedStartTime}`;
        }
        if(query.lastUpdatedEndTime) {
            url += `&lastUpdatedEndTime=${query.lastUpdatedEndTime}`
        }
        return ApiService.request({
            url: url,
            method: "get",
            data: query
        }, cancelator);
    }
}