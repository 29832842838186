import { AppSettingStore } from "./AppSettingStore";
import { AuthStore } from "./AuthStore";
import { BeaconStore } from "./BeaconStore";
import { CashFlowStore } from "./CashFlowStore";
import { CrowdsourcedLocationStore } from "./CrowdsourcedLocationStore";
import { MyInfoStore } from "./MyInfoStore";
import { TaskStore } from "./TaskStore";
import { ToastNotificationStore } from "./ToastNotificationStore";
import { UsersStore } from "./UsersStore";
import lines from "../assets/json/busline_to_id.json";
import { observable } from "mobx";
import { toggleTheme } from "../services/Theme.service";

export interface ReactSelectOption {
    value?: string;
    label?: string;
}

export class RootStore {
    private static YEAR_OF_START: number = 2019;
    public linesOptions: ReactSelectOption[];
    public myInfoStore: MyInfoStore;
    public auth: AuthStore;
    public usersStore: UsersStore;
    public settingStore: AppSettingStore;
    public cashFlowStore: CashFlowStore;
    public taskStore: TaskStore;
    public crowdsourcedLocationStore: CrowdsourcedLocationStore;
    public beaconStore: BeaconStore;
    public toastStore: ToastNotificationStore;
    @observable public isSidebarOpened: boolean = true;
    @observable public isSidebarSticky: boolean = true;
    @observable public currentTheme: string = "corporate";

    public init() {
        this.auth = new AuthStore(this);
        this.settingStore = new AppSettingStore();
        this.usersStore = new UsersStore();
        this.cashFlowStore = new CashFlowStore();
        this.taskStore = new TaskStore(this);
        this.crowdsourcedLocationStore = new CrowdsourcedLocationStore(this);
        this.beaconStore = new BeaconStore(this);
        this.toastStore = new ToastNotificationStore(this);
        this.myInfoStore = new MyInfoStore(this);
        this.linesOptions = [];
        Object.keys(lines).forEach((lineName) => {
            const arr = lines[lineName];
            if (arr.length > 0) {
                const splitName = lineName.split(":");
                this.linesOptions.push({
                    value: arr[0],
                    label: splitName[1]
                });
            }
        });
    }

    public toggleSidebar() {
        this.isSidebarOpened = !this.isSidebarOpened;
    }

    public toggleSidebarStickiness() {
        this.isSidebarSticky = !this.isSidebarSticky;
    }

    public enableClassicTheme() {
        this.enableTheme("classic");
        this.currentTheme = "classic";
    }
      
    public enableCorporateTheme() {
        this.enableTheme("corporate");
        this.currentTheme = "corporate";
    }
      
    public enableModernTheme() {
        this.enableTheme("modern");
        this.currentTheme = "modern";
    }
      
    public enableTheme(name: string) {
        toggleTheme(name);
    
    }

    public get setting() {
        return this.settingStore.Setting;
    }

    public get availableYearsToQuery() {
        const years: number[] = [];
        const currentYear = new Date().getFullYear();
        let year: number = RootStore.YEAR_OF_START;
        while (year <= currentYear) {
            years.push(year);
            year++;
        }
        return years;
    }
}

const instance = new RootStore();
export { instance as Root };