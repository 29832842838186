import * as React from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { Icon } from "react-feather";
import { Badge, Collapse } from "reactstrap";

interface SidebarCategoryProps extends RouteComponentProps {
    name: string;
    badgeColor: string;
    badgeText: string;
    icon: Icon;
    onClick: () => void;
    isOpen: boolean;
    to: string;
}

class SidebarCategory extends React.Component<SidebarCategoryProps, {}> {

    private getSidebarItemClass(path: string) {
        return this.props.location.pathname.indexOf(path) !== -1 ||
            (this.props.location.pathname === "/" && path === "/dashboard")
            ? "active"
            : "";
    }

    public render() {
        const Icon = this.props.icon;
        return (
            <li className={"sidebar-item " + this.getSidebarItemClass(this.props.to)}>
                <span
                    data-toggle="collapse"
                    className={"sidebar-link " + (!this.props.isOpen ? "collapsed" : "")}
                    onClick={this.props.onClick}
                    aria-expanded={this.props.isOpen ? "true" : "false"}
                >
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{this.props.name}</span>
                    {this.props.badgeColor && this.props.badgeText ? (
                        <Badge color={this.props.badgeColor} size={18} className="sidebar-badge">
                            {this.props.badgeText}
                        </Badge>
                    ) : null}
                </span>
                <Collapse isOpen={this.props.isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {this.props.children}
                    </ul>
                </Collapse>
            </li>
        );
    }
}

const wrapper = withRouter(SidebarCategory);
export { wrapper as SidebarCategory };