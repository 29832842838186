import BeaconService from "../services/Beacon.service";
import { ObservableMap } from "mobx";
import { RefreshStore } from "./RefreshStore";
import { RootStore } from "./RootStore";
import { Utils } from "../utils/Utils";

export class BeaconStore extends RefreshStore {
    private beaconInfoListCache = new ObservableMap<string, BeaconInfo[]>({});
    private beaconInfoCache = new ObservableMap<string, BeaconInfo>({});
    private beaconRegistrationInfoListCache = new ObservableMap<string, BeaconRegistrationInfo[]>({});
    private beaconRegistrationInfoCache = new ObservableMap<string, BeaconRegistrationInfo>({});
    private commissionBeaconRequestsListCache = new ObservableMap<string, BeaconCommissionRequest[]>({});
    private static EDDYSTONE: string = "EDDYSTONE";
    private root: RootStore;
    
    constructor(rootStore: RootStore) {
        super();
        this.root = rootStore;
    }

    public async getNewBeaconUID() {
        const apiResponse = await BeaconService.generateBeaconUID();
        return apiResponse.data.response;
    }

    public async getBeaconInfoList(query: PublicBeaconInfoQuery[]) {
        const key = Utils.getHash(query);
        const list = this.beaconInfoListCache.get(key);
        if (!list) {
            await this.updateBeaconInfoListCache(query);
            return this.beaconInfoListCache.get(key) || [];
        }
        return list;
    }

    public async updateBeaconInfoListCache(query: PublicBeaconInfoQuery[]) {
        const key = Utils.getHash(query);
        const apiResponse = await BeaconService.getBeacons(query);
        if (apiResponse.data.status === "OK") {
            this.beaconInfoListCache.set(key, apiResponse.data.response);
            apiResponse.data.response.forEach(beaconInfo => {
                const beaconId = Utils.convertBase64ToHexString(beaconInfo.advertisedId.id);
                this.beaconInfoCache.set(beaconId, beaconInfo);
            });
        }
    }

    public async getCommissionBeaconRequestList(query: BeaconCommissionRequestQuery) {
        const key = Utils.getHash(query);
        const list = this.commissionBeaconRequestsListCache.get(key);
        if (!list) {
            await this.updateCommissionBeaconRequestList(query);
            return this.commissionBeaconRequestsListCache.get(key) || [];
        }
        return list;
    }

    public async updateCommissionBeaconRequestList(query: BeaconCommissionRequestQuery) {
        const key = Utils.getHash(query);
        const apiResponse = await BeaconService.getCommissionRequests(query);
        if (apiResponse.data.status === "OK") {
            this.commissionBeaconRequestsListCache.set(key, apiResponse.data.response);
        }
    }

    public async getBeaconInfo(beaconId: string) {
        const beacon = this.beaconInfoCache.get(beaconId);
        if (!beacon) {
            const queryList: PublicBeaconInfoQuery[] = [
                {
                    beaconId: beaconId,
                    idType: BeaconStore.EDDYSTONE,
                    attachmentTypes: ["*"],
                }
            ]
            await this.updateBeaconInfoListCache(queryList);
            return this.beaconInfoCache.get(beaconId);
        }
        return beacon;
    }

    public async getBeaconRegistrationInfoList(query: BeaconRegistrationInfoQuery) {
        const key = Utils.getHash(query);
        const list = this.beaconRegistrationInfoListCache.get(key);
        if (!list) {
            await this.updateBeaconRegistrationInfoListCache(query);
            return this.beaconRegistrationInfoListCache.get(key) || [];
        }
        return list;
    }

    public async updateBeaconRegistrationInfoListCache(query: BeaconRegistrationInfoQuery) {
        const key = Utils.getHash(query);
        const apiResponse = await BeaconService.getBeaconRegistrationInfos(query);
        if (apiResponse.data.status === "OK") {
            this.beaconRegistrationInfoListCache.set(key, apiResponse.data.response);
            apiResponse.data.response.forEach(beaconInfo => {
                this.beaconRegistrationInfoCache.set(beaconInfo.beaconId, beaconInfo);
            });
        }
    }

}