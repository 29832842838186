export interface Theme {
    primary: string;
    secondary: string;
    tertiary: string;
    success: string;
    info: string;
    warning: string;
    danger: string;
}

class ThemeStore {
    private themes: Map<string, Theme>;
    
    constructor() {
        this.themes = new Map<string, Theme>();
        this.themes.set("classic", {
            primary: "#47bac1",
            secondary: "#a180da",
            tertiary: "#5fc27e",
            success: "#5fc27e",
            info: "#5b7dff",
            warning: "#fcc100",
            danger: "#f44455"
        });
        this.themes.set("corporate", {
            primary: "#3086ff",
            secondary: "#495057",
            tertiary: "#0069fc",
            success: "#4bbf73",
            info: "#1f9bcf",
            warning: "#f0ad4e",
            danger: "#d9534f"
        });
        this.themes.set("modern", {
            primary: "#2c7be5",
            secondary: "#9d7bd8",
            tertiary: "#5997eb",
            success: "#4caf50",
            info: "#47bac1",
            warning: "#ff9800",
            danger: "#e51c23"
        })
    }

    public getTheme(name: string) {
        return this.themes.get(name);
    }

}

const instance = new ThemeStore();
export { instance as ThemeStore };