import * as React from "react";

import { Card, CardBody, CardText, Col, Container, Label, Row } from "reactstrap";
import { inject, observer } from "mobx-react";

import { RootStore } from "../../stores/RootStore";

interface TaskDetailViewProps {
    root?: RootStore;
    task: Task;
}

@inject("root") @observer
export class TaskDetailView extends React.Component<TaskDetailViewProps, {}> {

    public render() {
        return (
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Details</h1>
                <Card>
                    <CardBody className="m-sm-3 m-md-5">
                        <Row>
                            <Col md="6">
                                <div className="text-muted">Task ID</div>
                                <strong>{this.props.task.id}</strong>
                            </Col>
                            <Col md="6" className="text-md-right">
                                <div className="text-muted">Date posted</div>
                                <strong>{new Date(this.props.task.createdTime).toUTCString()}</strong>
                            </Col>
                        </Row>

                        <hr className="my-4" />
                        <Label><strong>Description</strong></Label>
                        <CardText>
                            {this.props.task.description}
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}