import * as React from "react";

import { Spinner } from "reactstrap";

export class Loader extends React.Component {
    public render() {
        return (
            <div className="text-center mt-4">
                <Spinner/>
                {this.props.children}
            </div>
        );
    }
}