import * as React from "react";

import {
    Button,
    Card,
    CardBody,
} from "reactstrap";

import { RootStore } from "../../stores/RootStore";
import { inject } from "mobx-react";

interface TaskItemProps {
    root?: RootStore;
    task?: Task;
    onClick?: () => void;
}

inject("root")
export class TaskItem extends React.Component<TaskItemProps, {}> {
    public render() {
        return (
            <Card className="mb-3 bg-light cursor-grab border">
                <CardBody className="p-3">
                    <p>{this.props.task.description}</p>
                    <Button color="primary" size="sm" onClick={this.props.onClick}>
                        View
                    </Button>
                </CardBody>
            </Card>
        )
    }
}