import { Card, CardBody, CardHeader, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { Lambda, action, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../../stores/AppSettingStore";
import { Line } from "react-chartjs-2";
import { MoreHorizontal } from "react-feather";
import React from "react";
import { RenderingStatus } from "../../../enumerations/Enumerations";
import { RootStore } from "../../../stores/RootStore";
import { ThemeStore } from "../../../stores/ThemeStore";

interface OnboardingLineChartProps {
    root?: RootStore;
}

@inject("root") @observer 
export class OnboardingLineChart extends React.Component<OnboardingLineChartProps, {}> {
    private months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    @observable private commissionRequestList: BeaconCommissionRequest[] = [];
    @observable private renderingStatus: RenderingStatus = RenderingStatus.Waiting;
    private disposer: Lambda;
    @observable private yearSelected: number = new Date().getUTCFullYear();
    
    public componentDidMount() {
        this.fetchDataGivenYear(this.yearSelected);
        this.disposer = reaction(
            () => this.props.root.settingStore.willRefresh || this.props.root.cashFlowStore.willRefresh,
            () => {
                this.fetchDataGivenYear(this.yearSelected);
            }
        );
    }

    public componentWillUnmount() {
        this.disposer();
    }

    public render() {
        const theme = ThemeStore.getTheme(this.props.root.currentTheme);
        const data = {
            labels: this.months,
            datasets: [
                {
                    label: "Drivers onboarded",
                    fill: true,
                    backgroundColor: "transparent",
                    borderColor: theme.primary,
                    data: this.aggregateByMonths(this.commissionRequestList)
                },
            ]
        };
    
        const options = {
            maintainAspectRatio: false,
            legend: {
                display: true
            },
            tooltips: {
                intersect: false
            },
            hover: {
                intersect: true
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                xAxes: [
                    {
                        reverse: true,
                        gridLines: {
                            color: "rgba(0,0,0,0.05)"
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        borderDash: [5, 5],
                        gridLines: {
                            color: "rgba(0,0,0,0)",
                            fontColor: "#fff"
                        }
                    }
                ]
            }
        };
    
        return (
            <Card className="flex-fill w-100">
                <CardHeader>
                    <div className="card-actions float-right">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a">
                                <MoreHorizontal />
                            </DropdownToggle>
                            <DropdownMenu right>
                                {
                                    this.props.root.availableYearsToQuery.map(year => {
                                        return (
                                            <DropdownItem key={year} onClick={this.onClickDropdownItem(year)} >{year}</DropdownItem>
                                        );
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <CardTitle tag="h5" className="mb-0">
                        Drivers onboarded over time ({this.yearSelected})
                    </CardTitle>
                </CardHeader>
                {this.renderLineChart(data, options)}
            </Card>
        );
    }

    private renderLineChart(data, options) {
        switch (this.renderingStatus) {
            case RenderingStatus.Error:
                return (
                    <CardBody>
                        <div className="justify-content-center align-self-center w-100 text-center">Failed to load. Please try again.</div>
                    </CardBody>
                ); 
            case RenderingStatus.Waiting:
                return (
                    <CardBody>
                        <div className="mb-2 mr-3 mt-2 text-center">
                            <Spinner size="sm" className="mr-2"/>
                        </div> 
                    </CardBody>
                    
                );
            default:
                return (
                    <CardBody>
                        <div className="chart chart-lg">
                            <Line data={data} options={options} />
                        </div>
                    </CardBody>
                );
        }
    }

    private onClickDropdownItem = (year: number) => () => {
        this.yearSelected = year;
        this.fetchDataGivenYear(year);
    }

    private aggregateByMonths(requestList: BeaconCommissionRequest[]) {
        let userSetPerMonth = new Array<Set<string>>(12).fill(null);
        requestList.forEach(request => {
            const date = new Date(request.timestamp);
            const month = date.getMonth();
            if (!userSetPerMonth[month]) {
                userSetPerMonth[month] = new Set<string>();
            }
            userSetPerMonth[month].add(request.userId);
        });
        let data = new Array<number>(12).fill(0);
        userSetPerMonth.forEach((userSet, index) => {
            data[index] = userSet ? userSet.size : 0;
        });
        return data;
    }

    @action
    private async fetchDataGivenYear(year: number) {
        this.renderingStatus = RenderingStatus.Waiting;
        const firstDayOfYear = new Date(year, 0, 1);
        const lastDayOfYear = new Date(year, 11, 31);
        try {
            const query: BeaconCommissionRequestQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                submittedBy: this.props.root.auth.userUid,
                startTime: firstDayOfYear.getTime(),
                endTime: lastDayOfYear.getTime()
            };
            this.commissionRequestList = await this.props.root.beaconStore.getCommissionBeaconRequestList(query) || [];
            this.renderingStatus = RenderingStatus.Ready;
        } catch (err) {
            console.log(err);
            this.renderingStatus = RenderingStatus.Error;
        }  
    }
}