import {
    Card,
    CardHeader,
    Col,
    Input,
    Row,
    Spinner,
    Table,
} from "reactstrap";
import { Lambda, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";

import { AppSettingStore } from "../../stores/AppSettingStore";
import BeaconService from "../../services/Beacon.service";
import { PaginationWrapper } from "../common/PaginationWrapper";
import React from "react";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import moment from "moment";

interface OnboardedUsersTableProps {
    root?: RootStore;
}

@inject("root") @observer
export class OnboardedUsersTable extends React.Component<OnboardedUsersTableProps, {}> {
    @observable private userList: User[] = [];
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Waiting;
    @observable private searchWord: string = "";
    private disposers: Lambda[] = [];
    private MAX_ENGAGEMENT_SCORE = 5;
    private MAX_PAGE_SIZE = 10;
    @observable private engagementDataPerDriver: Map<string, BeaconEngagement> = new Map<string, BeaconEngagement>();
    @observable private filtered: User[] = [];
    @observable private currentPage: number = 0;
    
    public componentDidMount() {
        this.fetchOnboardedUsers();
        this.disposers.push(reaction(
            () => this.props.root.settingStore.willRefresh || this.props.root.beaconStore.willRefresh,
            () => {
                this.fetchOnboardedUsers();
            }
        ));
        this.disposers.push(reaction(
            () => this.searchWord,
            () => {
                this.filtered = this.userList.filter(this.searchWordFilter);
            }
        ));
    }

    public componentWillUnmount() {
        this.disposers.forEach(disposer => disposer());
    }

    public render() {
        return (
            <div>
                {
                    this.userList.length > 0 && 
                    <React.Fragment>
                        <hr/>
                        <h1 className="h3 mb-3">Users you've onboarded</h1>
                        <Card className="flex-fill w-100">
                            
                            <CardHeader>
                                <Row className="float-right">
                                    <Col>
                                        <Input className="form-control-bottom-border mr-sm-2" placeholder={"Filter..."}
                                            onChange={this.onChangeSearchWord} />
                                    </Col >
                                </Row>
                            </CardHeader>
                            {this.renderTable()}
                        </Card>
                    </React.Fragment>
                }    
            </div>
            
        );
    }

    private renderTable() {
        const pageCount = Math.ceil(this.filtered.length / this.MAX_PAGE_SIZE);
        switch (this.loadingStatus) {
            case RenderingStatus.Error:
                return (
                    <div className="justify-content-center align-self-center w-100 text-center">Failed to load. Please try again.</div>
                ); 
            case RenderingStatus.Waiting:
                return (
                    <div className="mb-2 mr-3 mt-2 text-center">
                        <Spinner size="sm" className="mr-2"/>
                    </div>                     
                );
            default:
                if (this.userList.length === 0) {
                    return (
                        <div className="mb-2 text-center">
                            You haven't onboarded anyone onto the platform yet.
                        </div>
                    ); 
                }
                return (
                        <div>
                            <Table striped hover className="my-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Phone number</th>
                                        <th className="d-none d-xl-table-cell">Bus ID</th>
                                        <th className="text-center">Engagement rating</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.filtered.slice(this.currentPage * this.MAX_PAGE_SIZE,
                                            (this.currentPage + 1) * this.MAX_PAGE_SIZE
                                          ).map(user => {
                                            return (
                                                <tr key={user.userId}>
                                                    <td> {`${user.firstName} ${user.lastName}`}</td>
                                                    <td>{user.phoneNumber}</td>
                                                    <td className="d-none d-xl-table-cell">{user.busId}</td>
                                                    <td className="text-center">{this.engagementScore(user.busId)}/{this.MAX_ENGAGEMENT_SCORE}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                            {
                                this.filtered.length > 0 && pageCount > 1 && 
                                <PaginationWrapper pagesCount={pageCount} handlePageClick={this.handlePageClick} />
                            }
                        </div>
                );
        }
    }

    private handlePageClick = (currentPage: number) => {
        this.currentPage = currentPage;
    }

    private engagementScore(beaconId: string) {
        var today = new Date();
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const firstDayFormatted = moment(firstDayOfCurrentMonth).format("MM/DD/YYYY");
        const lastDayFormatted = moment(lastDayOfCurrentMonth).format("MM/DD/YYYY");
        const thisMonthKey = `${firstDayFormatted}-${lastDayFormatted}`;
        const engagement = this.engagementDataPerDriver.get(beaconId);
        if (engagement && engagement.data) {
            let engagementScore = 0;
            const intervalKeys = Object.keys(engagement.data);
            intervalKeys.forEach(interval => {
                const activityData = engagement.data[interval];
                if (interval === thisMonthKey) {
                    const val = (activityData.numberOfDataPoints / (today.getDay() * 1440));
                    engagementScore += (val > 1 ? 1 : val) * this.MAX_ENGAGEMENT_SCORE;
                } else {
                    const intervalSplit = interval.split("-");
                    const lastDayStrSplit = intervalSplit[1].split("/");
                    const val = (activityData.numberOfDataPoints / (Number(lastDayStrSplit[1]) * 1440));
                    engagementScore += (val > 1 ? 1 : val) * this.MAX_ENGAGEMENT_SCORE;
                }
            });
            return engagementScore / intervalKeys.length;
        }
        return 0;
    }

    private onChangeSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.searchWord = event.target.value;
    }

    private async fetchOnboardedUsers() {
        const usersStore = this.props.root.usersStore;
        const beaconStore = this.props.root.beaconStore;
        this.loadingStatus = RenderingStatus.Waiting;

        try {
            const query: BeaconCommissionRequestQuery = {
                country: localStorage.getItem(AppSettingStore.SELECTED_COUNTRY_CACHE_KEY),
                submittedBy: this.props.root.auth.userUid
            };
            const commissionRequests = await beaconStore.getCommissionBeaconRequestList(query) || [];
            const usersSet = new Set<string>();
            commissionRequests.forEach(async request => {
                usersSet.add(request.userId);
                if (!this.engagementDataPerDriver.has(request.userId)) {
                    const engagementData = await BeaconService.getBeaconEngagementData(request.beaconId);
                    if (engagementData.data.response) {
                        this.engagementDataPerDriver.set(request.userId, engagementData.data.response);
                    }
                }
            });
            if (usersSet.size > 0) {
                const usersQuery: UserQuery = {
                    userIds: [...Array.from(usersSet)]
                };
                this.userList = await usersStore.getUsers(usersQuery) || [];
                this.filtered = this.userList;
            }
            this.loadingStatus = RenderingStatus.Ready;
        } catch (err) {
            console.log(err);
            this.loadingStatus = RenderingStatus.Error;
        }
    }

    private readonly searchWordFilter = (user: User) => {
        if (this.searchWord.trim()) {
            const textToSearch = [
                `${user.firstName} ${user.lastName}`,
                user.busId,
                user.phoneNumber
            ].join(" ").toLowerCase();
            return textToSearch.includes(this.searchWord.trim().toLowerCase());
        }
        return true;
    }
}
