import { computed, observable } from "mobx";

import { RenderingStatus } from "../enumerations/Enumerations";
import { RootStore } from "./RootStore";

export class MyInfoStore {
    @observable public profile: User;
    @observable private loadingStatus: RenderingStatus;
    private root: RootStore;

    constructor(root: RootStore) {
        this.root = root;
    }

    public async loadUserProfile() {
        try {
            this.loadingStatus = RenderingStatus.Waiting;
            if (this.root.auth.userUid) {
                const results = await this.root.usersStore.getUsers({
                    userId: this.root.auth.userUid
                }) || [];
                if (results.length > 0) {
                    this.profile = results[0];
                    this.loadingStatus = RenderingStatus.Ready;
                } else {
                    this.loadingStatus = RenderingStatus.Error;
                }
            }
        } catch(e) {
            console.log(e);
            this.loadingStatus = RenderingStatus.Error;
        }
    }

    @computed
    public get myInfoLoadingStatus() {
        return this.loadingStatus;
    }
}