import * as React from "react";

import { Button, Card, CardBody, CardHeader, CardTitle, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { ToastDuration, ToastMessageType } from "../../stores/ToastNotificationStore";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

import { FormSubmissionStatus } from "../common/FormSubmissionStatus";
import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";

interface ChangePasswordCardProps {
    root?: RootStore;
}

@inject("root") @observer
export class ChangePasswordCard extends React.Component<ChangePasswordCardProps, {}> {
    @observable private currentPassword: string = "";
    @observable private newPassword: string = "";
    @observable private confirmedNewPassword: string = "";
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Ready;
    @observable private submissionMesage: string = "";
    public render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5">Change password</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label>Current password</Label>
                                <Input value={this.currentPassword} type="password" name="currentPassword" 
                                        onChange={this.onChangeCurrentPassword}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>New password</Label>
                                <Input value={this.newPassword} type="password" name="password" 
                                        onChange={this.onChangeNewPassword}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Confirm new password</Label>
                                <Input value={this.confirmedNewPassword} type="password" name="confirmPassword"
                                        onChange={this.onChangeConfirmedNewPassword}/>
                            </FormGroup>
                            <div>
                                <Spinner size="sm" className="mr-2" hidden={this.loadingStatus !== RenderingStatus.Waiting}/>
                                <Button disabled={!this.formIsValid} onClick={this.submit} color="primary">Save new password</Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <FormSubmissionStatus status={this.loadingStatus} message={this.submissionMesage}/>
            </div>
            
        );
    }

    @computed
    private get formIsValid() {
        return !!this.currentPassword && !!this.newPassword && !!this.confirmedNewPassword;
    }

    private onChangeCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.currentPassword = event.target.value;
    }

    private onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.newPassword = event.target.value;
    }

    private onChangeConfirmedNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.confirmedNewPassword = event.target.value;
    }

    private submit = async () => {
        this.loadingStatus = RenderingStatus.Waiting;
        const isCurrentPasswordValid = await this.props.root.auth.isCurrentPasswordValid(this.currentPassword);
        if (this.newPassword === this.confirmedNewPassword) {
            if (isCurrentPasswordValid) {
                try {
                    await this.props.root.auth.updatePassword(this.newPassword);
                    this.loadingStatus = RenderingStatus.Ready;
                    this.submissionMesage = "";
                    this.currentPassword = "";
                    this.confirmedNewPassword = "";
                    this.newPassword = "";
                    this.props.root.toastStore.showNotif({
                        title: "",
                        message: "Password successfully updated",
                        duration: ToastDuration.Normal,
                        toastType: ToastMessageType.Success
                    });
                } catch (e) {
                    console.log(e);
                    this.submissionMesage = "Error while updating current password";
                    this.loadingStatus = RenderingStatus.Error;
                }
            } else {
                this.submissionMesage = "You have entered an invalid current password";
                this.loadingStatus = RenderingStatus.Error;
            }
        } else {
            this.submissionMesage = "The new password and the confirmation password do not match.";
            this.loadingStatus = RenderingStatus.Error;
        }
    }
}