import CrowdsourcedLocationService from "../services/CrowdsourcedLocation.service";
import { ObservableMap } from "mobx";
import { RootStore } from "./RootStore";
import { Utils } from "../utils/Utils";

export class CrowdsourcedLocationStore {
    private root: RootStore;
    private crowdsourcedLocationListCache = new ObservableMap<string, CrowdsourcedLocation[]>({});

    constructor(root: RootStore) {
        this.root = root;
    }

    public async getCrowdsourcedLocationList(query: CrowdsourcedLocationQuery) {
        const key = Utils.getHash(query);
        const list = this.crowdsourcedLocationListCache.get(key);
        if (!list) {
            await this.updateCrowdsourcedLocationListCache(query);
            return this.crowdsourcedLocationListCache.get(key);
        }
        return list;
    }

    public async updateCrowdsourcedLocationListCache(query: CrowdsourcedLocationQuery) {
        const key = Utils.getHash(query);
        const apiResponse = await CrowdsourcedLocationService.getCrowdsourcedLocationsGivenQuery(query);
        if (apiResponse.data.status === "OK") {
            this.crowdsourcedLocationListCache.set(key, apiResponse.data.response);
        }
    }
}