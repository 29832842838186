import * as React from "react";

import { Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
import { inject, observer } from "mobx-react";

import { RenderingStatus } from "../../enumerations/Enumerations";
import { RootStore } from "../../stores/RootStore";
import { observable } from "mobx";

interface PayoutTaskDetailViewProps {
    root?: RootStore;
    task: Task;
}

@inject("root") @observer
export class PayoutTaskDetailView extends React.Component<PayoutTaskDetailViewProps, {}> {
    @observable private loadingStatus: RenderingStatus = RenderingStatus.Waiting;
    @observable private cashActivity: CashActivity;
    @observable private user: User;

    public componentDidMount() {
        this.getCashActivity(this.props.task.cashActivityId);
    }

    public render() {
        switch (this.loadingStatus) {
            case RenderingStatus.Error:
                return (
                        <div className="mb-2 text-center">Failed to load. Please try again.</div>
                    ); 
            case RenderingStatus.Waiting:
                return (
                    <div className="mb-2 mr-3 mt-2 text-center">
                        <Spinner size="sm" className="mr-2"/>
                    </div>  
                );
            default:
                break;
        }
        if (this.cashActivity) {
            return (
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Details</h1>
                    <Card>
                        <CardBody className="m-sm-3 m-md-5">
                            <Row>
                                <Col md="6">
                                    <div className="text-muted">Task ID</div>
                                    <strong>{this.props.task.id}</strong>
                                </Col>
                                <Col md="6" className="text-md-right">
                                    <div className="text-muted">Date posted</div>
                                    <strong>{new Date(this.props.task.createdTime).toUTCString()}</strong>
                                </Col>
                            </Row>
    
                            <hr className="my-4" />
    
                            <Row className="mb-4">
                                <Col md="6">
                                    <div className="text-muted">From</div>
                                    <strong>BuzzTop</strong>
                                </Col>
                                {
                                    this.user && 
                                    <Col md="6" className="text-md-right">
                                        <div className="text-muted">Payment to</div>
                                            <strong>{`${this.user.firstName} ${this.user.lastName}`}</strong>
                                            <p>
                                                {this.user.phoneNumber}
                                            </p>
                                    </Col>
                                }
                                
                            </Row>
    
                            <Table size="sm">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th className="text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Reward for participating in crowdsourcing program</td>
                                        <td className="text-right">{`${this.cashActivity.amount} ${this.cashActivity.currency}`}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Container>
            );
        } else {
            return (
                <div className="mb-2 text-center">Failed to load. Please try again.</div>
            );
        }
        
    }

    private async getCashActivity(cashActivityId: string) {
        this.loadingStatus = RenderingStatus.Waiting;
        try {
            const result = await this.props.root.cashFlowStore.getCashActivityList({
                collection: "costs",
                id: cashActivityId
            }) || [];
            this.cashActivity = result.length > 0 ? result[0] : null;
            if (this.cashActivity) {
                const result = await this.props.root.usersStore.getUsers( { userId: this.cashActivity.settlementTo }) || [];
                this.user = result.length > 0 ? result[0] : null;
            }
            this.loadingStatus = RenderingStatus.Ready;
        } catch (e) {
            console.log(e);
            this.loadingStatus = RenderingStatus.Error;
        }
    }
}