import { inject, observer } from "mobx-react";

import React from "react";
import { RootStore } from "../../stores/RootStore";
import jQuery from "jquery";

const $ = jQuery;
window["jQuery"] = jQuery;

require("smartwizard/dist/js/jquery.smartWizard.min.js");

export interface FormWizardItem {
    title: string;
    key: string;
    description?: string;
    body?: JSX.Element;
}

interface FormWizardProps {
    root?: RootStore;
    variant?: string;
    color?: string;
    formItems?: FormWizardItem[];
    stepsValidationCallback?: (e: any, anchorObject: any, stepNumber: number, stepDirection: string) => boolean;
    onCompleteFormCallback?: () => Promise<boolean>;
}

@inject("root") @observer
export class FormWizard extends React.Component<FormWizardProps, {}> {

    public componentDidMount() {
        $(this.refs.smartwizard)
            .smartWizard({
                theme: this.props.variant, // default OR arrows
                showStepURLhash: false,
                toolbarSettings: {
                    toolbarExtraButtons: [
                        $(
                            '<button class="btn btn-submit btn-primary" type="button">Finish</button>'
                        )
                    ]
                }
            })
            .on("leaveStep", this.props.stepsValidationCallback);

        $(this.refs.smartwizard)
            .find(".btn-submit")
            .on("click", () => {
                this.resetForm();
                if (this.props.onCompleteFormCallback) {
                    this.props.onCompleteFormCallback();
                }
            });
    }


    public render() {
        const color  = this.props.color;
        const items = this.props.formItems || [];

        return (
            <div ref="smartwizard" className={`wizard wizard-${color} mb-4`}>
                <ul>
                    {
                        items.map(item => {
                            return (
                                <li key={item.key}>
                                    <a href={`#${item.key}`}>
                                        {item.title}
                                    <br />
                                    <small>{item.description}</small>
                                    </a>
                                </li> 
                            )
                        })
                    }
                </ul>

                <div>
                    {
                        items.map(item => {
                            return (
                                <div key={item.key} id={item.key}>
                                    {
                                        item.body
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    private resetForm() {
        $(this.refs.smartwizard).smartWizard("reset");
    }

}
    
