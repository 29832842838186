import { Col, Container, Row } from "reactstrap";

import { LayoutSettings } from "../nav/LayoutSettings";
import Main from "./Main";
import React from "react";

export class AuthLayout extends React.Component {
    public render() {
        return(
            <React.Fragment>
                <Main className="d-flex w-100">
                <Container className="d-flex flex-column">
                    <Row className="h-100">
                    <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">{this.props.children}</div>
                    </Col>
                    </Row>
                </Container>
                </Main>
                <LayoutSettings />
            </React.Fragment>
        );
    }
}